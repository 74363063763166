<template>
  <div v-if="productProvenance" class="position-relative product-provenance" id="top">
    <div id="navbar" :class="mobileNavIsOpen ? 'mobile-open' : ''">
      <div class="bg-black py-6 text-5xl justify-between container sticky top-0 z-99 hidden md:flex">
        <div class="cursor-pointer flex items-center invisible">
          <img src="@/assets/images/search-icon-white.png" alt="">
        </div>
        <router-link class="font-gotham" to="/">XM</router-link>
        <div class="flex items-center relative">
          <img class="cursor-pointer" src="@/assets/images/search-icon-white.png" alt=""
          @click="openFloatingSearchBar">
          <div class="explore-box-profile bg-black px-6 py-8 border-1 border-white absolute top-12 right-0"
            v-click-outside="closeFloatingSearchBar"
            v-if="isFloatingSearchBar">
            <div class="flex flex-col">
              <div class="relative">
                <div class="explore-input search">
                  <div class="flex">
                    <img class="px-3" src="../assets/images/search-icon.png" alt="">
                    <input :value="searchKey" class="flex-auto" type="text" placeholder="Serial number or character name"
                      v-debounce:400ms="onSearchTyping"
                      @input="onSearchInput"
                      @keydown.enter="searchStatueProfiles">
                    <img class="px-3 cursor-pointer" src="../assets/images/circle-x-icon.png" alt=""
                      @click="clearSearchField">
                    <div class="explore-input-label">Search</div>
                  </div>
                  <div ref="searchList" class="pt-3 search-list bg-black flex flex-col gap-2 text-gray"
                    :class="statueProfiles.length > 0 ? 'flex' : 'hidden'">
                    <router-link :to="`/productprovenance?id=${statueProfile.serialNumber}`"
                      v-for="statueProfile in statueProfiles" :key="statueProfile">
                      <div class="text-start px-14">
                        <div class="text-base">{{ statueProfile.product.productName }}</div>
                        <div class="text-xs">{{ statueProfile.serialNumber }}</div>
                      </div>
                    </router-link>
                  </div>
                </div>
              </div>
              <div class="mt-14">
                <div class="mt-1 pl-4 text-start text-xs text-red"
                  v-if="statueProfiles.length === 0 && isSearchFinished">Serial number not found.</div>
                <div class="flex justify-end" :class="statueProfiles.length === 0 && isSearchFinished ? 'pt-1' : 'pt-6'">
                  <div class="explore-button" @click="searchStatueProfiles">{{ isSearching ? `Searching...` : `Search` }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="nav-wrapper-mobile">
        <div class="logo">
          <a href="#top" @click="mobileNavOnClick(false)">
            <img :src="logoImg" />
          </a>
        </div>
        <div class="expand-icon" @click="mobileNavOnClick(!mobileNavIsOpen)">
          <i v-if="mobileNavIsOpen" class="el-icon-close"></i>
          <i v-else class="fa fa-bars" aria-hidden="true"></i>
        </div>
      </div>
      <div class="nav-wrapper pb-3">
        <a href="#owner" @click="mobileNavOnClick(false)">Owner</a>
        <a href="#artists" @click="mobileNavOnClick(false)">Artist</a>
        <a href="#designs" @click="mobileNavOnClick(false)">Design Concepts</a>
        <a href="#sustainability" @click="mobileNavOnClick(false)">Sustainability Report</a>
        <a
          href="#autograph"
          v-if="productProvenance.artistAutograph.length > 0"
          @click="mobileNavOnClick(false)"
        >Artist Autograph</a>
        <a
          href="#autograph"
          v-if="isOwner"
          @click="mobileNavOnClick(false); openAutographModal();"
        >Upload Autograph</a>
      </div>
    </div>
    <div class="product-banner">
      <img class="banner" :src="productProvenance.product.productBackgroundImage" />
      <div class="arrow-down">
        <a href="#owner">
          <img :src="arrowDownImg" />
        </a>
      </div>
      <div class="product-name">{{ productProvenance.product.productName }}</div>
    </div>
    <div class="position-relative">
      <img class="bg-img" :src="productProvenance.product.productDisplayImage" />
      <div class="bg-img"></div>
      <div>
        <div class="section container pb-3">
          <div class="row pt-5 flex-wrap-reverse flex-lg-wrap" id="owner">
            <div
              class="col-12 col-lg-3 d-flex align-items-center justify-content-center justify-content-lg-start"
            >
              <!-- <div class="pb-1">
                <img
                  class="profile-img"
                  src="https://images.pexels.com/photos/771742/pexels-photo-771742.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"
                />
              </div>-->
              <div class="pt-3 pt-lg-0">
                <h6>Owned by</h6>
                <template v-if="customerDetails">
                  <div class="pb-1">
                    <div class="header">{{ customerDetails.customerName }}</div>
                    <div class="info">Owned since 31 March 2022</div>
                  </div>
                </template>
                <template v-else>
                   <div class="pb-1">-</div>
                </template>
              </div>
            </div>
            <div class="col-12 col-lg-9">
              <div class="mb-3">
                <h1 class="sn">{{ productProvenance.productSerialNumber.serialNumber }}</h1>
                <h2>
                  Edition
                  {{
                  `${productProvenance.productSerialNumber.edition}/${productProvenance.product.productQuantity}`
                  }}
                </h2>
                <div class="product-hash-wrapper" @click="provenanceEventModal = true" v-if="productProvenance.provenanceEvent.length > 0">
                  <div class="product-hash">
                    <span class="hash">Hash: {{ productProvenance.provenanceEvent[0].provenanceEventHash }}</span>
                  </div>
                  <i class="fa fa-external-link ms-3" aria-hidden="true"></i>
                </div>
              </div>
              <div
                class="text-md-start text-sm-center"
              >{{ productProvenance.product.productDescription }}</div>
            </div>
          </div>
        </div>
        <div class="section container pb-3">
          <div class="row g-3 pt-5 flex-column-reverse flex-md-row">
            <div class="col-12 col-md-9">
              <div class="mb-3 text-md-start text-sm-center">
                <h6>Background</h6>
              </div>
              <div
                class="text-md-start text-sm-center mb-2"
              >{{ productProvenance.product.productBackground }}</div>
              <div class="text-md-start text-sm-center">
                <router-link
                  :to="`/productlibrary?id=${productProvenance.product.productId}`"
                  target="_blank"
                >
                  <el-button
                    plain
                    class="custom-btn white-border-btn"
                    style="font-size: 17px;"
                  >View Library</el-button>
                </router-link>
              </div>
            </div>
          </div>
          <div class="row pt-5 justify-content-center pb-3" id="artists" v-if="artists.length > 0">
            <div class="col-12 col-md-4" v-for="artist in artists" :key="artist.artistId">
              <h6>The Artist</h6>
              <div class="pb-1">
                <img class="profile-img" :src="artist.artistDisplayPhotoFilePath" />
              </div>
              <div class="pb-1">
                <div class="header">{{ artist.artistName }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="section container" v-if="productProvenance.designConcepts.length > 0">
          <div class="row pt-5" id="designs">
            <div class="col-12 pb-4">
              <h2>Design Concepts</h2>
            </div>
            <div class="col-12 design-carousel">
              <DesignCarousel :designs="productProvenance.designConcepts" />
            </div>
          </div>
        </div>
        <!-- <div class="section container pb-3" v-if="productProvenance.product.sustainabilityReport"> -->
        <div class="section container pb-3" v-if="false">
          <div class="row pt-5" id="sustainability">
            <div class="col-12 d-md-block d-lg-none">
              <h2>Sustainability Report</h2>
            </div>
            <div
              class="col-12 col-lg-3 mb-3 mb-lg-0 d-flex align-items-center justify-content-center"
            >
              <img
                class="sustainability-report-img"
                :src="productProvenance.product.sustainabilityReport.sustainabilityReportImg"
              />
            </div>
            <div class="col-12 col-lg-9">
              <h2 class="text-start d-none d-lg-block">Sustainability Report</h2>
              <div
                class="mb-3 text-start"
              >{{ productProvenance.product.sustainabilityReport.sustainabilityReportDescription }}</div>
              <table>
                <tr v-for="(detail, index) in sustainabilityDetail" :key="index">
                  <td class="meterial-save-unit">{{ detail.totalSave }}</td>
                  <td class="meterial-save">{{ detail.resourceAndSaveMethod }}</td>
                </tr>
              </table>
            </div>
            <div class="col-12 mt-3">
              <a
                :href="productProvenance.product.sustainabilityReport.sustainabilityReportFile"
                target="_blank"
              >
                <el-button
                  plain
                  class="custom-btn white-border-btn"
                  style="font-size: 17px;"
                >View Report</el-button>
              </a>
            </div>
          </div>
        </div>
        <div
          class="section container pb-3"
          v-if="productProvenance.artistAutograph.length > 0 && artists.length > 0"
          id="autograph"
        >
          <div class="row pt-5">
            <div class="col-12">
              <h2>Artist Autograph</h2>
            </div>
            <div class="col-12">
              <AutographCarousel
                :productArtists="artists"
                :autographs="productProvenance.artistAutograph"
                :isOwner="isOwner"
                :serialNumberId="productProvenance.productSerialNumber.productSerialNumberId"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <AddAutographModal
      :modalClose="closeAutographModal"
      :productArtists="artists"
      :modalStatus="isAddAutograph"
      :serialNumberId="productProvenance.productSerialNumber.productSerialNumberId"
    />
    <el-dialog custom-class="provenence-event-dialog" v-model="provenanceEventModal" fullscreen>
      <ProductProvenanceEvent
        v-if="artists.length > 0 && !isPreview"
        :serialNumber="productProvenance.productSerialNumber.serialNumber"
        :provenanceEvents="productProvenance.provenanceEvent"
        :artists="artists"
      />
    </el-dialog>
    <div class="back-button flex flex-col justify-center fixed top-14 md:top-36 left-0 md:left-12"
      @click="this.$router.back()">BACK</div>
  </div>
  <FullPageLoader v-else />
</template>

<script>
import 'vue3-carousel/dist/carousel.css';
import { onMounted, ref, onBeforeUnmount } from 'vue';
import { vue3Debounce } from 'vue-debounce';
import vClickOutside from 'click-outside-vue3';
import axios from 'axios';
import { getUrlParams } from '../helpers';
import DesignCarousel from '../components/DesignCarousel.vue';
import AddAutographModal from '../components/AddAutographModal.vue';
import AutographCarousel from '../components/AutographCarousel.vue';
import ProductProvenanceEvent from '../components/ProductProvenanceEvent.vue';
import logo from '../assets/logo.png';
import arrowDown from '../assets/imgs/provenance/arrow-down.svg';
import FullPageLoader from '../components/Share/FullPageLoader.vue';

export default {
  directives: {
    clickOutside: vClickOutside.directive,
    debounce: vue3Debounce({
      lock: true,
    }),
  },
  components: {
    DesignCarousel,
    AddAutographModal,
    AutographCarousel,
    ProductProvenanceEvent,
    FullPageLoader,
  },
  setup() {
    const logoImg = logo;
    const arrowDownImg = arrowDown;
    const productProvenance = ref();
    const sustainabilityDetail = ref([]);
    const artists = ref([]);
    const serialNumber = ref('');
    const isPreview = ref('');
    const customerId = ref('');
    const customerDetails = ref(null);
    const isOwner = ref(false);
    const isAddAutograph = ref(false);
    const provenanceEventModal = ref(false);
    const mobileNavIsOpen = ref(false);
    const isSearchActive = ref(false);
    const isSearchFinished = ref(false);
    const isSearching = ref(false);
    const searchKey = ref('');
    const statueProfiles = ref([]);
    const searchStatueProfiles = async () => {
      isSearching.value = true;
      const searchUrl = `${process.env.VUE_APP_ROUTER_DOMAIN}/api/xm/product/v1/searchproductserialnumber/${searchKey.value}`;
      await axios
        .get(searchUrl)
        .then((res) => {
          isSearchFinished.value = true;
          // console.log(res.data);
          statueProfiles.value = res.data;
          isSearchActive.value = res.data.length > 0;
          isSearching.value = false;
        })
        .catch(() => {
          isSearchFinished.value = true;
          isSearchActive.value = false;
          isSearching.value = false;
        });
    };
    const onSearchInput = async (event) => {
      searchKey.value = event.target.value;
    };
    const onSearchTyping = async () => {
      // console.log(searchKey.value);
      searchStatueProfiles();
    };
    const clearSearchField = () => {
      statueProfiles.value = [];
      searchKey.value = '';
      isSearchActive.value = false;
      isSearchFinished.value = false;
      // this.$refs.searchList.classList.add('hidden');
    };

    onMounted(async () => {
      serialNumber.value = getUrlParams('id');
      isPreview.value = getUrlParams('preview');
      customerId.value = getUrlParams('user');

      // const loginUrl = `${process.env.VUE_APP_GENERIC_ROUTER_DOMAIN}/api/user/v1/auth`;
      // await axios
      //   .post(loginUrl, {
      //     userName: process.env.VUE_APP_GUEST_USERNAME,
      //     secret: process.env.VUE_APP_GUEST_PASSWORD,
      //     applicationDomain: 'xm',
      //   })
      //   .then((res) => {
      //     localStorage.setItem('token', res.data.token);
      //   });

      if (serialNumber.value !== null && isPreview.value === null) {
        const productProvenanceUrl = `${process.env.VUE_APP_ROUTER_DOMAIN}/api/xm/product/v1/productprovenance/${serialNumber.value}`;
        axios.get(productProvenanceUrl).then((response) => {
          productProvenance.value = response.data;
          document.title = `${response.data.product.productName} - ${response.data.productSerialNumber.serialNumber}`;
          sustainabilityDetail.value = JSON.parse(response.data.product.sustainabilityReport.sustainabilityReportDetail);
          if (customerId.value !== null && response.data.productSerialNumber.customerId.toLowerCase() === customerId.value.toLowerCase()) {
            isOwner.value = true;
          }

          const artistUrl = `${process.env.VUE_APP_ROUTER_DOMAIN}/api/xm/artist/v1/artists`;
          axios.get(artistUrl).then((artistRes) => {
            response.data.product.artistIds.forEach((id) => {
              const artist = artistRes.data.find((x) => x.artistId === id);
              artists.value.push(artist);
            });
          });

          if (response.data.productSerialNumber.customerId) {
            const customerUrl = `${process.env.VUE_APP_ROUTER_DOMAIN}/api/xm/customer/v1/customer/${response.data.productSerialNumber.customerId}`;
            axios.get(customerUrl).then((customerRes) => {
              customerDetails.value = customerRes.data;
            });
          }
        });
      }

      if (serialNumber.value !== null && isPreview.value === '1') {
        const productProvenanceUrl = `${process.env.VUE_APP_ROUTER_DOMAIN}/api/xm/product/v1/productprovenancepreview/${serialNumber.value}`;
        axios.get(productProvenanceUrl).then((response) => {
          productProvenance.value = response.data.productProvenance;
          document.title = `${productProvenance.value.product.productName}`;
          sustainabilityDetail.value = JSON.parse(productProvenance.value.product.sustainabilityReport.sustainabilityReportDetail);
          artists.value = response.data.artists;
        });
      }
    });

    const openAutographModal = () => {
      isAddAutograph.value = true;
    };

    const closeAutographModal = () => {
      isAddAutograph.value = false;
    };

    const mobileNavOnClick = (navStatus) => {
      mobileNavIsOpen.value = navStatus;
      if (navStatus) {
        document.body.classList.add('no-scroll');
      } else {
        document.body.classList.remove('no-scroll');
      }
    };

    const isFloatingSearchBar = ref(false);

    const openFloatingSearchBar = () => {
      isFloatingSearchBar.value = true;
      // document.body.classList.add('modal-open');
    };

    const closeFloatingSearchBar = () => {
      isFloatingSearchBar.value = false;
      // clearSearchField();
      // document.body.classList.remove('modal-open');
    };

    const handleNavbar = () => {
      // if (window.scrollY > (window.innerHeight - 50)) {
      if (window.scrollY > 100) {
        document.getElementById('navbar').style.top = '0px';
      } else {
        document.getElementById('navbar').style.top = '-146px';
        closeFloatingSearchBar();
        mobileNavIsOpen.value = false;
      }
    };

    window.addEventListener('scroll', handleNavbar);

    onBeforeUnmount(() => {
      handleNavbar();
    });

    return {
      mobileNavIsOpen,
      isOwner,
      isPreview,
      productProvenance,
      sustainabilityDetail,
      serialNumber,
      artists,
      isAddAutograph,
      openAutographModal,
      closeAutographModal,
      provenanceEventModal,
      logoImg,
      arrowDownImg,
      mobileNavOnClick,
      customerDetails,
      searchKey,
      isSearchActive,
      isSearchFinished,
      isSearching,
      statueProfiles,
      isFloatingSearchBar,
      clearSearchField,
      searchStatueProfiles,
      onSearchInput,
      onSearchTyping,
      openFloatingSearchBar,
      closeFloatingSearchBar,
    };
  },
};
</script>

<style lang="scss">
.back-button {
  font-family: 'gotham';
  font-size: 24px;
  cursor: pointer;
  width: 190px;
  height: 76px;
}

.product-provenance {
  color: #fff;
}

.product-name {
  font-family: 'gotham';
}

.product-banner {
  height: 100vh;
  width: 100%;
  position: relative;

  .banner {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
  }

  .arrow-down {
    position: absolute;
    bottom: 10px;
    width: 100%;
    -webkit-animation: fade_move_down 2s ease-in-out infinite;
    -moz-animation: fade_move_down 2s ease-in-out infinite;
    animation: fade_move_down 2s ease-in-out infinite;

    img {
      margin: 0 auto;
      cursor: pointer;
    }
  }

  .product-name {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    font-size: 50px;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 10px;
    border-radius: 5px;
    user-select: none;
  }
}

/*animated scroll arrow animation*/
@-webkit-keyframes fade_move_down {
  0% {
    -webkit-transform: translate(0, -18px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translate(0, 18px);
    opacity: 0;
  }
}
@-moz-keyframes fade_move_down {
  0% {
    -moz-transform: translate(0, -18px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -moz-transform: translate(0, 18px);
    opacity: 0;
  }
}
@keyframes fade_move_down {
  0% {
    transform: translate(0, -18px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translate(0, 18px);
    opacity: 0;
  }
}

.section {
  font-size: 18px;
  position: relative;
}

.profile-img {
  border-radius: 50%;
  width: 220px;
  height: 220px;
  object-fit: cover;
  background: #ffffff;
  margin: 0 auto;
}

.header {
  font-size: 18px;
  font-weight: bold;
}

.info {
  font-size: 18px;
  font-weight: 300;
}

.sn {
  margin-bottom: 0px;
  line-height: 1;
}

.bg-img {
  opacity: 0.9;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background: black;
}

#navbar {
  background-color: #000000;
  color: #ffffff;
  width: 100%;
  transition: all 0.3s;
  font-size: 14px;
  // top: -50px;
  top: -146px;
  position: fixed;
  z-index: 1;
  // height: 50px;

  .nav-wrapper-mobile {
    font-family: 'gotham';
    text-transform: uppercase;
    display: none;
    position: relative;

    .logo {
      cursor: pointer;
      img {
        height: 50px;
        width: 100px;
      }
    }
  }

  .nav-wrapper {
    font-family: 'gotham';
    text-transform: uppercase;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100%;

    a {
      color: #ffffff;
      text-decoration: none;
    }
  }

  @media (max-width: 768px) {
    .nav-wrapper {
      display: none;
    }

    .nav-wrapper-mobile {
      display: flex;
      align-items: center;
      justify-content: center;

      .expand-icon {
        position: absolute;
        right: 15px;
        font-size: 30px;
        color: #ffffffd1;
      }
    }
  }
}

@media (max-width: 768px) {
  #navbar.mobile-open {
    height: 100vh !important;
    overflow-y: auto;

    .nav-wrapper {
      display: flex;
      flex-flow: column;
      justify-content: unset;
      background: black;

      a {
        color: #ffffff;
        text-decoration: none;
        padding: 25px 0;
        font-size: 25px;
      }
    }
  }
}

// .StepProgress {
//   position: relative;
//   padding-left: 45px;
//   list-style: none;
//   text-align: left;
//   margin-bottom: 1rem;

//   a {
//     text-decoration: none;
//     color: #aaaaaa;
//     cursor: pointer;
//   }
// }
// .StepProgress::before {
//   display: inline-block;
//   content: '';
//   position: absolute;
//   top: 8px;
//   left: 13px;
//   width: 10px;
//   height: 90%;
//   border-left: 5px solid #aaaaaa;
// }
// .StepProgress-item {
//   position: relative;
//   counter-increment: list;
// }

// .StepProgress-item:last-child::before {
//   height: 0;
// }

// .StepProgress-item:not(:last-child) {
//   padding-bottom: 20px;
// }

// .StepProgress-item::before {
//   display: inline-block;
//   content: '';
//   position: absolute;
//   left: -32px;
//   height: 100%;
//   width: 10px;
//   top: 8px;
//   border-left: 5px solid #5586aa;
// }
// .StepProgress-item::after {
//   content: '';
//   display: inline-block;
//   position: absolute;
//   top: 5px;
//   left: -41px;
//   width: 23px;
//   height: 23px;
//   border: 4px solid #5586aa;
//   border-radius: 50%;
//   background-color: #5586aa;
// }

// .StepProgress-item.active::after {
//   color: #5586aa;
//   border: 4px solid #5586aa;
//   background-color: white;
// }

// .StepProgress-item.active::before {
//   border-left: 4px solid #aaaaaa;
// }

// .StepProgress-item.active ~ ::after {
//   border: 4px solid #aaaaaa;
//   border-radius: 50%;
//   background-color: #fff;
// }

// .StepProgress-item.active ~ ::before {
//   border-left: 5px solid #aaaaaa;
// }

// .StepProgress-item.active {
//   a {
//     color: #ffffff;
//   }
// }

.design-carousel {
  font-size: 18px;
  font-weight: 300;
}

.product-hash-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.custom-link,
.product-hash {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #5586aa !important;
  cursor: pointer;
  font-size: 18px !important;

  .hash {
    text-decoration: underline;
    font-style: italic;
  }
}

.sustainability-report-img {
  width: 220px;
  height: 220px;
  border-radius: 50%;
  object-fit: cover;
}

.meterial-save-unit {
  font-family: 'gotham';
  font-size: 22px;
  width: fit-content;
  text-align: right;
}

.meterial-save {
  text-align: left;
  padding-left: 20px;
}

.provenence-event-dialog {
  .el-dialog__body {
    padding: 10px !important;
  }
}

// popup search field
.explore-box-profile {
  width: 500px;
  max-height: 376px;
}
@media (max-width: 768px) {
  .explore-box-profile {
    width: 100%;
  }
  .explore-input.search {
    .search-list {
      height: 232px;
      overflow: auto;
    }
  }
}
.explore-input {
  position: relative;
  border: 1px solid #FFFFFF;
  border-radius: 4px;
  padding: 16px 0;
  background: black;
  &.search {
    position: absolute !important;
    // width: -webkit-fill-available;
    width: 100%;
    z-index: 0;
  }
  input {
    background: none !important;
    border: none !important;
    outline: none !important;
  }
  .explore-input-label {
    position: absolute;
    top: 0;
    left: 16px;
    transform: translateY(-50%);
    background: black;
    padding: 0 4px;
    font-size: 12px;
  }
  .search-list {
    a:hover {
      color: white;
    }
  }
}
.explore-box-profile {
  .search-list {
    height: 500px;
    overflow: auto;
  }
}
.explore-button {
  font-family: 'gotham';
  width: 114px;
  border: 1px solid #FFFFFF;
  padding: 8px 0;
  cursor: pointer;
  font-size: 12px;
  color: white !important;
}
</style>
