const FILE_SUPPORTED_FORMATS = [
  'image/jpg',
  'image/jpeg',
  'image/gif',
  'image/png',
  'image/svg+xml',
  'image/webp',
  'image/avif',
  'image/apng',
  'video/x-flv',
  'video/mp4',
  'application/x-mpegURL',
  'video/MP2T',
  'video/3gpp',
  'video/quicktime',
  'video/x-msvideo',
  'video/x-ms-wmv',
];
const IMAGE_FORMAT = [
  'image/jpg',
  'image/jpeg',
  'image/gif',
  'image/png',
  'image/svg+xml',
  'image/webp',
  'image/avif',
  'image/apng',
];
const VIDEO_FORMAT = [
  'video/x-flv',
  'video/mp4',
  'application/x-mpegURL',
  'video/MP2T',
  'video/3gpp',
  'video/quicktime',
  'video/x-msvideo',
  'video/x-ms-wmv',
];

const VIS_OPTION = {
  nodes: {
    size: 80,
    mass: 3,
    borderWidthSelected: 5,
    color: {
      background: '#000',
      border: '#5EAB6B',
      highlight: {
        border: '#5EAB6B',
      },
    },
  },
  edges: {
    smooth: {
      enabled: false,
      type: 'continuous',
      roundness: 0,
    },
    selectionWidth: 5,
    length: 400,
    dashes: true,
    physics: true,
    color: {
      color: '#FFFFFF',
      highlight: '#5EAB6B',
    },
    font: {
      color: '#ffffff',
      size: 20,
      strokeWidth: 0,
    },
  },
  interaction: {
    dragNodes: false,
    dragView: true,
    zoomView: true,
  },
  layout: {
    clusterThreshold: 300,
  },
  physics: {
    barnesHut: {
      avoidOverlap: 1,
    },
  },
};

export { FILE_SUPPORTED_FORMATS,
  IMAGE_FORMAT,
  VIDEO_FORMAT,
  VIS_OPTION };
