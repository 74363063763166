<template>
  <div>
    <label class="img-label">{{ formLabel }}
      <div class="format-label">{{ formatLabel }}</div>
    </label>
    <el-form-item :prop="formProps" ref="formitem" class="upload-container">
      <el-upload
        action
        list-type="picture"
        :show-file-list="false"
        :on-change="handleFile"
        :auto-upload="false"
        :accept="fileFormat"
        drag
      >
        <img v-if="imgUrl && isImage" :src="imgUrl" class="image" />
        <video class="image" v-if="imgUrl && !isImage" :key="imgUrl" autoplay loop muted controls>
          <source :src="imgUrl" />Your browser does not support HTML video.
        </video>
        <div v-if="!imgUrl">
          <i class="el-icon-upload"></i>
          <div>
            Drop file here or
            <em>click to upload</em>
          </div>
        </div>

        <!-- <img v-if="imgUrl" :src="imgUrl" class="image" />
        <div v-else>
          <i class="el-icon-upload"></i>
          <div>Drop file here or <em>click to upload</em></div>
        </div>-->
      </el-upload>
      <i v-if="imgUrl" class="el-icon-delete clear-img-icon" @click="clearImg"></i>
    </el-form-item>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { IMAGE_FORMAT } from '@/common/constants';

export default {
  props: {
    imgUrl: String,
    imgFile: File,
    formLabel: {
      type: String,
      required: true,
    },
    formatLabel: {
      type: String,
      required: true,
    },
    formProps: {
      type: String,
      required: true,
    },
    fileFormat: {
      type: String,
      required: true,
    },
  },
  emits: ['update:imgUrl', 'update:imgFile'],
  setup(props, context) {
    const formitem = ref(null);
    const fileType = ref(null);

    const handleFile = (file) => {
      context.emit('update:imgUrl', URL.createObjectURL(file.raw));
      context.emit('update:imgFile', file.raw);
      fileType.value = file.raw.type;
      formitem.value.elForm.validateField(props.formProps);
    };

    const clearImg = () => {
      context.emit('update:imgUrl', null);
      context.emit('update:imgFile', null);
      formitem.value.elForm.validateField(props.formProps);
    };

    const isImage = computed(() => IMAGE_FORMAT.some((x) => x === fileType.value));

    return {
      formitem,
      // fileFormat: IMAGE_FORMAT.join(','),
      handleFile,
      clearImg,
      isImage,
    };
  },
};
</script>
