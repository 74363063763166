<template>
  <div class="text-white py-4">
    <div class="mb-4">
      <h4>{{ serialNumber }}</h4>
      <!-- <div class="fst-italic">{{ `Hash: ${transactionHax}` }}</div> -->
    </div>
    <div class="timeline">
      <div
        class="event-container"
        v-for="(event, index) in provenanceEvents"
        :key="event.provenanceEventId"
        :class="index % 2 === 0 ? 'right' : 'left'"
      >
        <div v-if="event.eventFileUrl" class="timeline-circle" :style="styledEventFileUrl(index)"></div>
        <div v-if="event.artistId" class="timeline-circle" :style="styledArtistImg(event.artistId)"></div>
        <div v-if="!event.artistId && !event.eventFileUrl" class="timeline-circle">
          <img v-if="event.event.toLowerCase().includes('nft')" src="../assets/imgs/provenance/product-mint.svg" />
          <img v-if="event.event.toLowerCase().includes('qc')" src="../assets/imgs/provenance/product-qc.svg" />
          <img v-if="event.event.toLowerCase().includes('owner')" src="../assets/imgs/provenance/owner-register.svg" />
        </div>
        <div class="text-break">
          <div class="event-name">{{ event.event }}</div>
          <div>{{ eventDate(event.eventTime) }}</div>
          <div>
            <ProvenanceEventHash :provenanceEvent="event" :currentPosition="index % 2 === 0 ? 'right' : 'left'" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import productMintIcon from '../assets/imgs/provenance/product-mint.svg';
// import ownerRegisterIcon from '../assets/imgs/provenance/owner-register.svg';
// import productQcIcon from '../assets/imgs/provenance/product-qc.svg';
import ProvenanceEventHash from './ProvenanceEventHash.vue';

export default {
  components: {
    ProvenanceEventHash,
  },
  props: {
    serialNumber: String,
    transactionHax: {
      type: String,
      default: '10e38ufe873248fe8cqv7',
    },
    provenanceEvents: {
      type: Array,
      required: true,
    },
    artists: {
      type: Array,
      required: true,
    },
  },
  computed: {
    eventDate() {
      return (dateTime) => dayjs(dateTime).format('DD/MM/YYYY HH:mm');
    },
    styledEventFileUrl() {
      return (index) => ({
        background: `url(${this.provenanceEvents[index].eventFileUrl}), #000`,
        backgroundSize: 'cover',
      });
    },
    styledArtistImg() {
      return (artistId) => ({
        background: `url(${this.artists.find((x) => x.artistId === artistId).artistDisplayPhotoFilePath}), #000`,
        backgroundSize: 'cover',
      });
    },
  },
  methods: {
    styledEventIcon() {
      const backgroundIcon = productMintIcon;
      return {
        background: `url(${backgroundIcon}), #000`,
        backgroundSize: 'cover',
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.timeline {
  position: relative;

  // &::after {
  //   content: '';
  //   position: absolute;
  //   width: 5px;
  //   background-color: #fff;
  //   top: 0;
  //   bottom: 80px;
  //   left: 50%;
  //   margin-left: -3px;
  // }

  .event-container {
    position: relative;
    width: 50%;
    padding-top: 20px;
    min-height: 180px;

    .timeline-circle {
      content: '';
      position: absolute;
      width: 100px;
      height: 100px;
      right: -50px;
      border: 5px solid #ffffff;
      top: 0;
      border-radius: 50%;
      z-index: 1;
      background-color: #000;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 60%;
        height: 60%;
      }

      // &::after {
      //   content: '';
      //   position: absolute;
      //   border-right: 5px solid #ffffff;
      // }
    }

    &::after {
      content: '';
      position: absolute;
      border-right: 6px solid #ffffff;
      height: 100%;
      bottom: -100px;
    }

    &.left {
      left: 0;
      text-align: right;
      padding-right: 65px;
      padding-left: 20px;

      &::after {
        right: -2px;
      }
    }

    &.right {
      left: 50%;
      text-align: left;
      padding-left: 65px;
      padding-right: 20px;

      &::after {
        left: -2px;
      }

      .timeline-circle {
        left: -50px;
      }
    }

    .event-name {
      font-family: gotham;
      text-transform: uppercase;
    }

    &:last-child {
      &.left,
      &.right {
        &::after {
          border-right: unset !important;
        }
      }

      .timeline-circle {
        &::after {
          content: '';
          position: absolute;
          border-right: 6px dotted #ffffff;
          height: 100%;
          left: 46%;
          bottom: -100px;
        }
      }
    }
  }
}
</style>
