<template>
  <Transition>
  <div class="delete-entries-modal" v-if="modalStatus">
    <div class="modal-container m-auto flex flex-col justify-center">
      <div class="body mx-auto">
        <div class="text-red text-4xl font-gotham">Delete entry?</div>
        <div class="text-start mt-14 text-xl">
          This action cannot be undone. Do you want to proceed?
        </div>
        <div class="mt-40 flex justify-between">
          <div class="submit-button px-3 py-2 font-gotham text-xl cursor-pointer" @click="modalClose">CANCEL</div>
          <div class="submit-button px-3 py-2 font-gotham text-xl text-red cursor-pointer uppercase"
            :class="deleting ? 'opacity-50' : 'opacity-100'"
            @click="deletePendingAutograph">
            {{ deleting ? 'Deleting...' : 'Delete' }}
          </div>
        </div>
      </div>
    </div>
  </div>
  </Transition>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    modalClose: {
      type: Function,
      required: true,
    },
    modalStatus: {
      type: Boolean,
      required: true,
    },
    productAutographId: {
      type: String,
      required: true,
    },
  },
  watch: {
    modalStatus(newModalStatus) {
      this.showModal = newModalStatus;
    },
    showModal(newShowModal) {
      if (!newShowModal) {
        this.modalClose();
      }
    },
  },
  methods: {
    deletePendingAutograph() {
      if (this.deleting === false) {
        this.deleting = true;
        const apiUrl = `${process.env.VUE_APP_ROUTER_DOMAIN}/api/xm/product/v1/productautograph/${this.productAutographId}`;
        axios.delete(apiUrl).then(() => {
          window.location.reload();
        });
      }
    },
  },
  data() {
    return {
      deleting: false,
    };
  },
};
</script>
<style lang="scss">
.font-gotham {
  font-family: 'gotham';
}
.delete-entries-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #000000e6;
  color: white;
  position: fixed;
  top: 0;
  z-index: 1;
  width: -webkit-fill-available;
  min-height: 100vh;
  .modal-container {
    background: black;
    width: 1375px;
    height: 735px;
    border-radius: 30px;
    .body {
      width: 664px;
      height: fit-content;
    }
  }
}
.package-list {
  .package {
    width: 221px;
    height: 293px;
    background-image: url('../assets/images/explore/package-border.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
}
.package-button {
  font-family: 'gotham';
  font-size: 16px;
  cursor: pointer;
  width: 180px;
  margin: auto;
  padding: 12px 0;
  border: 1px solid #FFFFFF;
}
// Transition Styles
.v-enter-active,
.v-leave-active {
  transition: all 0.2s ease-in-out;
}

.v-enter-from,
.v-leave-to {
  transform: translateY(-15px);
  opacity: 0;
}
</style>
