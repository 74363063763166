<template>
  <Carousel :settings="carouselSetting" :breakpoints="carouselBreakpoints">
    <Slide v-for="design in designs" :key="design.designId">
      <div class="carousel__item" @click="designOnClick(design.designId)">
        <img :src="design.designFilePath" />
      </div>
    </Slide>

    <template #addons="{ currentSlide }">
      {{ slideOnChange(currentSlide) }}
      <Navigation />
    </template>
  </Carousel>

  <div>
    <div class="mt-4 design-concept">{{ designs[currentSlide].designConcept }}</div>
    <div class="show-more-btn" @click="designOnClick(designs[currentSlide].designId)">Show Details</div>
  </div>

  <div id="myModal" class="modal" v-if="selectedDesign">
    <span class="close" @click="modalOnClose">
      <i class="el-icon-close"></i>
    </span>

    <div class="modal-container">
      <div class="modal-content">
        <img :src="selectedDesign.designFilePath" />
      </div>
      <div id="caption">{{ selectedDesign.designConcept }}</div>
    </div>
  </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css';
import { ref } from 'vue';
import { Carousel, Slide, Navigation } from 'vue3-carousel';

export default {
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  props: {
    designs: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const carouselSetting = ref({
      itemsToShow: 1,
      wrapAround: true,
      mouseDrag: false,
      touchDrag: false,
    });
    const carouselBreakpoints = ref({
      768: {
        itemsToShow: 3.95,
      },
    });
    const currentSlide = ref(0);

    const slideOnChange = (index) => {
      currentSlide.value = index;
    };

    const selectedDesign = ref(null);

    const modalOnClose = () => {
      // const navWrapper = document.getElementsByClassName('nav-wrapper');
      // navWrapper[0].style.display = 'flex';
      document.body.classList.remove('no-scroll');
      selectedDesign.value = null;
    };

    const escCloseModal = (event) => {
      if (event.code === 'Escape') {
        modalOnClose();
        window.removeEventListener('keyup', escCloseModal);
      }
    };

    const designOnClick = (id) => {
      selectedDesign.value = props.designs.find((x) => x.designId === id);
      // const navWrapper = document.getElementsByClassName('nav-wrapper');
      // navWrapper[0].style.display = 'none';
      document.body.classList.add('no-scroll');
      window.addEventListener('keyup', escCloseModal);
    };

    return {
      carouselSetting,
      carouselBreakpoints,
      designOnClick,
      currentSlide,
      slideOnChange,
      modalOnClose,
      selectedDesign,
    };
  },
};
</script>

<style lang="scss" scoped>
.design-concept {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.show-more-btn {
  cursor: pointer;
  font-weight: bold;
}

.modal {
  display: block;
  position: fixed; /* Stay in place */
  z-index: 100; /* Sit on top */
  padding-top: 50px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: #000000; /* Black w/ opacity */
}

.modal-container {
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;

  /* Modal Content (Image) */
  .modal-content {
    display: flex;
    height: 500px;
    max-width: 500px;
    padding: 10px;
    background: #000;
    align-items: center;
    justify-content: center;

    img {
      height: auto;
      width: auto;
      max-height: 100%;
      max-width: 100%;
      border-radius: 5px;
    }
  }

  /* Caption of Modal Image (Image Text) - Same Width as the Image */
  #caption {
    display: block;
    max-width: 50%;
    max-height: 500px;
    text-align: left;
    color: #ffffff;
    padding: 10px;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    overflow-y: auto;
  }
}

/* Add Animation - Zoom in the Modal */
.modal-content,
#caption {
  animation-name: zoom;
  animation-duration: 0.6s;
}

@keyframes zoom {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

/* The Close Button */
.close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #ffffff;
  font-size: 30px;
  font-weight: bold;
  transition: 0.3s;
}

.close:hover,
.close:focus {
  // color: #bbb;
  // text-decoration: none;
  cursor: pointer;
}

/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px) {
  .modal-content {
    width: 100%;
  }

  .modal-container {
    display: block;

    .modal-content {
      max-width: unset;
      width: 100%;
      height: 400px;
    }

    /* Caption of Modal Image (Image Text) - Same Width as the Image */
    #caption {
      max-width: 100%;
    }
  }
}
</style>
