<template>
  <div
    class="d-flex align-items-center"
    :class="currentPosition === 'right' ? 'justify-content-start' : 'justify-content-end'"
  >
    <div class="fst-italic text-ellipsis pe-2">
      <span>{{ `Hash: ${provenanceEvent.provenanceEventHash}` }}</span>
    </div>
    <div>
      <div v-if="loading">
        <i class="fa fa-circle-o-notch loader" aria-hidden="true"></i>
      </div>
      <div v-else>
        <i v-if="isVerify" class="fa fa-check-circle" style="color: #5EAB6B;" aria-hidden="true"></i>
        <i v-else class="fa fa-times-circle" style="color: #FF0000;" aria-hidden="true"></i>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    provenanceEvent: {
      type: Object,
      required: true,
    },
    currentPosition: String,
  },
  mounted() {
    this.loading = true;
    const productProvenanceUrl = `${process.env.VUE_APP_GENERIC_ROUTER_DOMAIN}/api/traceability-xm/v3/verifyblock`;
    const verifiybody = {
      entryHash: this.provenanceEvent.provenanceEventHash,
      metadata: {
        ProvenanceEventId: this.provenanceEvent.provenanceEventId,
        Event: this.provenanceEvent.event,
        EventTime: this.provenanceEvent.eventTime,
        EventFileUrl: this.provenanceEvent.eventFileUrl,
        EventFileType: this.provenanceEvent.eventFileType,
        ArtistId: this.provenanceEvent.artistId,
        ProvenanceEventHash: null,
        ProductSerialNumberId: this.provenanceEvent.productSerialNumberId,
      },
    };

    axios
      .post(productProvenanceUrl, verifiybody, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((response) => {
        this.loading = false;
        this.isVerify = response.data.verification;
      });
  },
  data() {
    return {
      loading: false,
      isVerify: false,
    };
  },
};
</script>
