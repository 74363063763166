<template>
  <div style="height: 100vh; background: #000000;">
    <div class="header" v-if="currentProduct">
      <div
        class="d-flex align-items-center"
        style="overflow-x: auto;"
      >
        <span
          class="header-text"
          :class="selection === 'license' ? 'selected' : ''"
          @click="assignSelectionQuery('license')"
        >
          {{ currentProduct.character.license.licenseName }}
        </span>|
        <span
          class="header-text"
          :class="selection === 'characters' ? 'selected' : ''"
          @click="assignSelectionQuery('characters')"
        >
          {{ currentProduct.character.characterName }}
        </span>|
        <span
          class="header-text"
          :class="selection === 'series' ? 'selected' : ''"
          @click="assignSelectionQuery('series')"
        >
          {{ currentProduct.productSeries }}
        </span>
      </div>
    </div>

    <LicensesLibrary
      v-if="selection == 'license'"
      :characterId="currentProduct.character.characterId"
      :headerHeight="headerHeight"
    />
    <CharactersLibrary
      v-if="selection == 'characters'"
      :characterId="currentProduct.character.characterId"
      :productId="currentProduct.productId"
      :headerHeight="headerHeight"
    />
    <SeriesLibrary
      v-if="selection == 'series'"
      :assignSelectionQuery="assignSelectionQuery"
      :assignCurrentProduct="assignCurrentProduct"
      :headerHeight="headerHeight"
    />
  </div>
</template>

<script>
import LicensesLibrary from '../components/LicensesLibrary.vue';
import CharactersLibrary from '../components/CharactersLibrary.vue';
import SeriesLibrary from '../components/SeriesLibrary.vue';

export default {
  components: {
    LicensesLibrary,
    CharactersLibrary,
    SeriesLibrary,
  },
  methods: {
    assignSelectionQuery(selected) {
      const query = {
        ...this.$route.query,
        selection: selected,
      };

      this.$router.replace({
        query,
      });

      this.selection = selected;
    },
    assignCurrentProduct(product) {
      this.currentProduct = product;
    },
  },
  watch: {
    currentProduct(newProduct) {
      setTimeout(() => {
        document.title = newProduct.productName;
        this.headerHeight = document.getElementsByClassName('header')[0].offsetHeight;
      }, 1);
    },
  },
  data() {
    return {
      currentProduct: null,
      selection: 'series',
      headerHeight: 0,
    };
  },
};
</script>

<style lang="scss" scoped>
.header {
  color: #000000;
  padding: 20px 0;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  width: 100%;

  .header-text {
    cursor: pointer;
    margin: 0 20px;

    &.selected {
      border-bottom: 1px solid #000000;
      font-weight: bold;
    }
  }
}
</style>
