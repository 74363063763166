<template>
  <div class="bg-black">
    <ProductProvenanceEvent
      v-if="artists.length > 0 && productProvenance"
      :serialNumber="productProvenance.productSerialNumber.serialNumber"
      :provenanceEvents="productProvenance.provenanceEvent"
      :artists="artists"
    />
    <FullPageLoader v-else />
  </div>
</template>

<script>
import { onMounted, ref } from 'vue';
import axios from 'axios';
import { getUrlParams } from '../helpers';
import ProductProvenanceEvent from '../components/ProductProvenanceEvent.vue';
import FullPageLoader from '../components/Share/FullPageLoader.vue';

export default {
  components: {
    ProductProvenanceEvent,
    FullPageLoader,
  },
  setup() {
    const artists = ref([]);
    const productProvenance = ref();

    onMounted(async () => {
      const serialNumber = getUrlParams('id');
      const loginUrl = `${process.env.VUE_APP_GENERIC_ROUTER_DOMAIN}/api/user/v1/auth`;
      await axios
        .post(loginUrl, {
          userName: process.env.VUE_APP_GUEST_USERNAME,
          secret: process.env.VUE_APP_GUEST_PASSWORD,
          applicationDomain: 'xm',
        })
        .then((res) => {
          localStorage.setItem('token', res.data.token);
        });

      if (serialNumber !== null) {
        const productProvenanceUrl = `${process.env.VUE_APP_ROUTER_DOMAIN}/api/xm/product/v1/productprovenance/${serialNumber}`;
        axios.get(productProvenanceUrl).then((response) => {
          productProvenance.value = response.data;
          document.title = `${response.data.product.productName} - ${response.data.productSerialNumber.serialNumber}`;

          const artistUrl = `${process.env.VUE_APP_ROUTER_DOMAIN}/api/xm/artist/v1/artists`;
          axios.get(artistUrl).then((artistRes) => {
            response.data.product.artistIds.forEach((id) => {
              const artist = artistRes.data.find((x) => x.artistId === id);
              artists.value.push(artist);
            });
          });
        });
      }
    });

    return {
      artists,
      productProvenance,
    };
  },
};
</script>

<style lang="scss" scoped>
</style>
