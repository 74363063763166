export function getUrlParams(params) {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(params);
}

export function convertToPascalCase(text) {
  return text.replace(/\w+/g,
    (w) => w[0].toUpperCase() + w.slice(1).toLowerCase());
}

export function firstWordUpperCase(text) {
  return `${text.charAt(0).toUpperCase()}${text.slice(1)}`;
}
