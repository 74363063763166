<template>
  <div id="mynetwork" :style="{ height: `calc(100% - ${headerHeight}px)` }"></div>
</template>

<script>
import { Network } from 'vis-network';
import axios from 'axios';
import 'vis-network/styles/vis-network.css';
import { getUrlParams } from '../helpers';
import { VIS_OPTION } from '../common/constants';

export default {
  props: {
    assignSelectionQuery: {
      type: Function,
      required: true,
    },
    assignCurrentProduct: {
      type: Function,
      required: true,
    },
    headerHeight: {
      type: Number,
      default: 0,
    },
  },
  async mounted() {
    const productId = getUrlParams('id');
    const productLibraryUrl = `${process.env.VUE_APP_ROUTER_DOMAIN}/api/xm/product/v1/productlibrary/${productId}`;
    this.assignSelectionQuery('series');
    let libraryData = null;
    await axios.get(productLibraryUrl).then((res) => {
      libraryData = res.data;
    });

    const nodesData = [];
    const edgesData = [];

    if (libraryData.length > 0) {
      this.assignCurrentProduct(libraryData.find((x) => x.product.productId === productId.toLowerCase()).product);
    }

    libraryData.forEach((data) => {
      const nodeObj = {
        id: data.product.productId,
        title: data.product.productName,
        shape: 'circularImage',
        image: data.product.productDisplayImage,
        borderWidth: data.product.productId === productId.toLowerCase() ? 10 : 1,
      };
      nodesData.push(nodeObj);
      data.relatedProduct.forEach((relatedProduct) => {
        const edgeObj = {
          from: data.product.productId,
          to: relatedProduct.relatedProduct.productId,
          label: relatedProduct.relation,
        };
        edgesData.push(edgeObj);
      });
    });

    // create a network
    const container = document.getElementById('mynetwork');
    const data = {
      nodes: nodesData,
      edges: edgesData,
    };
    const options = {
      ...VIS_OPTION,
    };
    options.layout.randomSeed = productId;

    const network = new Network(container, data, options);
    network.on('click', (properties) => {
      const clickedProductIds = properties.nodes;
      const selectedProduct = libraryData.find((x) => x.product.productId === clickedProductIds[0]);
      if (typeof selectedProduct !== 'undefined') {
        window.open(selectedProduct.product.productLink, '_blank');
      }
    });
  },
};
</script>
