<template>
  <Transition>
  <div class="package-listing-modal pt-52 pb-28 md:py-0" v-if="modalStatus">
    <div class="font-gotham text-4xl w-full mx-auto absolute top-4 block md:hidden">XM</div>
    <a href="javascript:void" @click="modalClose">
      <img src="@/assets/images/close-icon.png" alt="" class="absolute top-12 right-14 hidden md:block">
    </a>
    <div class="text-2xl font-gotham w-64 md:w-auto mx-auto">Want to make your collectible’s profile your own?</div>
    <div class="mt-4 text-base">
      Customize your collectible profiles with photos, videos and more, <br class="hidden md:block">
      to document the unique experiences on your collectibles journey!
    </div>
    <div class="pt-12 package-list flex flex-col md:flex-row gap-10 md:gap-28 justify-center">
      <div class="flex flex-col justify-center gap-10"
        :class="userPackage !== null && userPackage.collectibleProfilePackageId === packageItem.collectibleProfilePackageId ?
        'opacity-70' : 'opacity-100'"
        v-for="packageItem in packages" :key="packageItem">
        <div class="package text-center px-7 py-9 flex flex-col justify-center mx-auto">
          <div class="text-2xl font-gotham">{{ packageItem.packageName }}</div>
          <div class="text-xl font-semibold">USD{{ packageItem.monthPrice }}/month</div>
          <div class="text-base font-semibold">USD{{ packageItem.yearPrice }}/year</div>
          <div class="mt-6 text-xl">
            • {{ packageItem.numOfProfiles }} statue profiles <br>
            • {{ packageItem.numOfProfiles * packageItem.sizePerProfile }}MB total ({{ packageItem.sizePerProfile }}MB/profile)
          </div>
        </div>
        <div
          v-if="userPackage !== null && userPackage.collectibleProfilePackageId === packageItem.collectibleProfilePackageId">
          <div class="package-button cursor-pointer">
            Currently active
          </div>
          <div class="mt-2 text-xs">Plan ends on: {{ moment(userPackage.subscriptionEndDate).format('L') }}</div>
        </div>
        <div v-else>
          <div class="package-button cursor-pointer"
            @click="subscribeOrUpgradePackage(packageItem.collectibleProfilePackageId)">
            Get {{ packageItem.packageName }}
          </div>
          <div class="mt-2 text-xs invisible">Plan ends on: {{ userPackage.subscriptionEndDate }}</div>
        </div>
      </div>
    </div>
    <div class="pt-12">
      <div class="text-2xl font-gotham">Need more?</div>
      <div class="mt-10 package-button">Contact Us!</div>
      <div class="mt-10 package-button border-0 block md:hidden" @click="modalClose">Back</div>
    </div>
  </div>
  </Transition>
</template>

<script>
import { onMounted } from 'vue';
import moment from 'moment';
import { xmAxios } from '@/plugins/axios';

export default {
  props: {
    modalClose: {
      type: Function,
      required: true,
    },
    modalStatus: {
      type: Boolean,
      required: true,
    },
    packages: {
      type: Array,
      required: true,
    },
    userPackage: {
      type: Object,
      required: true,
    },
    closeTransaction: {
      type: Function,
      required: true,
    },
  },
  watch: {
    modalStatus(newModalStatus) {
      this.showModal = newModalStatus;
    },
    showModal(newShowModal) {
      if (!newShowModal) {
        this.modalClose();
      }
    },
  },
  data(props) {
    console.log(props.userPackage);
    // console.log(props.userPackage.collectibleProfilePackageId);
    const subscribeOrUpgradePackage = async (packageId) => {
      if (props.userPackage.length === 0) {
        props.closeTransaction();
        const startTransaction = `${process.env.VUE_APP_ROUTER_DOMAIN}/api/xm/product/v1/starttransaction/${packageId}`;
        await xmAxios.post(startTransaction).then((response) => {
          if (response.data) {
            // console.log(response.data);
            window.location.href = response.data.paymentUrl;
          }
        });
      } else {
        const changeSubscriptionUrl = `${process.env.VUE_APP_ROUTER_DOMAIN}/api/xm/product/v1/changeSubscription`;
        await xmAxios.post(changeSubscriptionUrl, {
          packageId,
        }).then((response) => {
          console.log(response.data);
          this.$router.push({
            name: 'Payment Success',
          });
        });
      }
    };
    onMounted(async () => {
      // get list of packages
      // const getPackagesUrl = `${process.env.VUE_APP_ROUTER_DOMAIN}/api/xm/product/v1/getpackages`;
      // axios.get(getPackagesUrl).then((response) => {
      //   // console.log(response.data);
      //   packages.value = response.data;
      // });
    });
    return {
      moment,
      subscribeOrUpgradePackage,
    };
  },
};
</script>
<style lang="scss">
.font-gotham {
  font-family: 'gotham';
}
.package-listing-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #000000e6;
  color: white;
  position: fixed;
  top: 0;
  z-index: 1;
  width: -webkit-fill-available;
  min-height: 100vh;
  .container {
    max-width: 868px;
  }
}
@media (max-width: 768px) {
.package-listing-modal {
  overflow: auto;
  display: block;
  height: 100vh;
}
}
.package-list {
  .package {
    width: 221px;
    height: 293px;
    background-image: url('../assets/images/explore/package-border.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
}
.package-button {
  font-family: 'gotham';
  font-size: 16px;
  cursor: pointer;
  width: 180px;
  margin: auto;
  padding: 12px 0;
  border: 1px solid #FFFFFF;
}
// Transition Styles
.v-enter-active,
.v-leave-active {
  transition: all 0.2s ease-in-out;
}

.v-enter-from,
.v-leave-to {
  transform: translateY(-15px);
  opacity: 0;
}
</style>
