/* ============
 * Axios
 * ============
 *
 * Promise based HTTP client for the browser and node.js.
 * Because Vue Resource has been retired, Axios will now been used
 * to perform AJAX-requests.
 *
 * https://github.com/mzabriskie/axios
 */

// import Vue from "vue";
import axios from 'axios';
import router from '@/router';

export const xmAxios = axios.create({
  headers: {
    common: {
      Accept: 'application/json',
    },
  },
});

export const genericAxios = axios.create({
  headers: {
    common: {
      Accept: 'application/json',
    },
  },
});

xmAxios.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    if (localStorage.getItem('token') != null) {
      config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
    }
    return config;
  },
  (error) => (
    // Do something with request error
    Promise.reject(error)
  ),
);

xmAxios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401 || error.response.status === 403) {
      localStorage.removeItem('token');
      localStorage.removeItem('user_uuid');
      localStorage.removeItem('user_name');
      router.push('/');
    }
    Promise.reject(error);
    return {
      status: 'error',
    };
  },
);

genericAxios.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    if (localStorage.getItem('token') != null) {
      config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
    }
    return config;
  },
  (error) => (
    // Do something with request error
    Promise.reject(error)
  ),
);

genericAxios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401 || error.response.status === 403) {
      localStorage.removeItem('token');
      localStorage.removeItem('user_uuid');
      router.push('/');
    }

    Promise.reject(error);
    return {
      status: 'error',
      error,
    };
  },
);
