<template>
  <el-dialog
    v-model="showModal"
    width="30%"
    fullscreen
    :before-close="dislogClose"
  >
    <el-row :gutter="20" justify="center">
      <el-col :span="24" :md="12">
        <el-form
          :model="form"
          :rules="formRules"
          label-position="top"
          ref="form"
          label-width="120px"
          class="demo-form"
        >
          <el-row :gutter="20"  class="mb-10 pe-3">
            <el-col :span="24">
              <h3 style="word-break: break-word;" class="text-2xl md:text-4xl">Submit Artist Autograph</h3>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24" :sm="12">
               <SelectInput
                  v-model="form.artist"
                  formProps="artist"
                  formLabel="Artist"
                >
                  <el-option
                    v-for="artist in productArtists"
                    :key="artist.artistId"
                    :label="artist.artistName"
                    :value="artist.artistId"
                  >
                  </el-option>
                </SelectInput>
            </el-col>
            <el-col :span="24" :sm="12">
              <Datepicker
                v-model="form.date"
                formProps="date"
                formLabel="Date of Autograph"
                :disabledDate="disabledDate"
              />
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
              <TextInput
                v-model="form.eventName"
                formProps="eventName"
                formLabel="Event name"
              />
            </el-col>
          </el-row>
          <el-row :gutter="20" style="text-align: left;">
            <el-col :span="24">
              <SingleMediaUpload
                v-model:imgUrl="form.autographUrl"
                v-model:imgFile="form.autographFile"
                formProps="autographUrl"
                formLabel="Upload Photo of Autograph"
                :fileFormat="imgFileFormat"
              />
            </el-col>
          </el-row>
          <el-row :gutter="20" style="text-align: left;">
            <el-col :span="24">
              <SingleMediaUpload
                v-model:imgUrl="form.autographProofUrl"
                v-model:imgFile="form.autographProofFile"
                formProps="autographProofUrl"
                formLabel="Upload image/video proof"
                formatLabel="For verification purposes, photo or video proof of the artist signing the autograph is required"
                :fileFormat="mediaFileFormat"
              />
            </el-col>
          </el-row>
          <el-row :gutter="30" justify="end">
            <el-col :span="24">
              <el-form-item style="text-align: right">
                <el-button
                  class="discard-btn"
                  type="text"
                  @click="resetForm"
                  :disabled="submitingForm"
                >
                  Discard
                </el-button>
                <el-button
                  class="custom-btn success-btn"
                  @click="submitForm"
                  :loading="submitingForm"
                >
                  Submit
                </el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-col>
    </el-row>
  </el-dialog>
</template>

<script>
import dayjs from 'dayjs';
import axios from 'axios';
// import { ElMessage } from 'element-plus';
import { IMAGE_FORMAT, FILE_SUPPORTED_FORMATS } from '../common/constants';
import TextInput from './Share/TextInput.vue';
import Datepicker from './Share/DateInput.vue';
import SelectInput from './Share/SelectInput.vue';
import SingleMediaUpload from './Share/SingleMediaUpload.vue';

export default {
  props: {
    modalClose: {
      type: Function,
      required: true,
    },
    modalStatus: {
      type: Boolean,
      required: true,
    },
    productArtists: {
      type: Array,
      required: true,
    },
    serialNumberId: {
      type: String,
      required: true,
    },
  },
  components: {
    TextInput,
    Datepicker,
    SelectInput,
    SingleMediaUpload,
  },
  watch: {
    modalStatus(newModalStatus) {
      this.showModal = newModalStatus;
    },
    showModal(newShowModal) {
      if (!newShowModal) {
        this.modalClose();
      }
    },
  },
  methods: {
    disabledDate(date) {
      if (date > new Date()) {
        return true;
      }

      return false;
    },
    // handleAutograph(file) {
    //   this.autographUrl = file.url;
    //   this.form.autographFile = file.raw;
    //   this.$refs.form.validateField('autographFile');
    // },
    // handleAutographProof(file) {
    //   this.autographProofUrl = file.url;
    //   this.form.autographProofFile = file.raw;
    //   this.autographProofFileType = file.raw.type;
    //   this.$refs.form.validateField('autographProofFile');
    // },
    async fileUpload(file) {
      const formData = new FormData();
      formData.append('file', file);

      const fileUploadUrl = `${process.env.VUE_APP_GENERIC_ROUTER_DOMAIN}/api/uploads/v1/xm`;
      return axios
        .post(fileUploadUrl, formData, {
          headers: {
            Accept: 'multipart/form-data',
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        })
        .then(
          (response) => `${process.env.VUE_APP_FILE_DOMAIN + response.data}`,
        );
    },
    submitForm() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.submitingForm = true;
          const loginUrl = `${process.env.VUE_APP_GENERIC_ROUTER_DOMAIN}/api/user/v1/auth`;
          await axios.post(loginUrl, {
            userName: process.env.VUE_APP_GUEST_USERNAME,
            secret: process.env.VUE_APP_GUEST_PASSWORD,
            applicationDomain: 'xm',
          }).then((res) => {
            localStorage.setItem('token', res.data.token);
          });

          const body = {
            autographArtistId: this.$refs.form.model.artist,
            autographDate: dayjs(this.$refs.form.model.date).format('YYYY-MM-DDT00:00:00'),
            eventName: this.$refs.form.model.eventName,
            productAutographUrl: 'string',
            productAutographProofUrl: 'string',
            productAutographProofType: 'string',
          };

          const autographFileUrl = await this.fileUpload(
            this.$refs.form.model.autographFile,
          );
          const autographProofUrl = await this.fileUpload(
            this.$refs.form.model.autographProofFile,
          );
          body.productAutographUrl = autographFileUrl;
          body.productAutographProofUrl = autographProofUrl;
          body.productAutographProofType = this.$refs.form.model.autographProofFile.type;
          const artistUrl = `${process.env.VUE_APP_ROUTER_DOMAIN}/api/xm/product/v1/productautograph/${this.serialNumberId}`;
          axios.post(artistUrl, body).then(() => {
            window.location.reload();
          });
        }
      });
    },
    resetForm() {
      this.$refs.form.resetFields();
      // this.autographProofUrl = null;
      // this.autographUrl = null;
    },
    dislogClose(done) {
      this.resetForm();
      done();
    },
  },
  data() {
    const validateUploadFile = async (rule, value, callback) => {
      console.log(value);
      const blob = await fetch(value).then((r) => r.blob());
      const blobSizeInMB = (blob.size / 1048576).toFixed(2);
      console.log(blobSizeInMB);

      if (blobSizeInMB > 25) {
        callback(new Error());
      } else {
        callback();
      }
    };

    return {
      showModal: this.modalStatus,
      imgFileFormat: IMAGE_FORMAT.join(','),
      mediaFileFormat: FILE_SUPPORTED_FORMATS.join(','),
      submitingForm: false,
      form: {
        artist: '',
        date: '',
        eventName: '',
        autographUrl: null,
        autographProofUrl: null,
        autographFile: null,
        autographProofFile: null,
      },
      formRules: {
        artist: [
          {
            required: true,
            message: 'Please select an artist',
            trigger: 'change',
          },
        ],
        date: [
          {
            type: 'date',
            required: true,
            message: 'Please pick a date',
            trigger: 'change',
          },
        ],
        eventName: [
          {
            required: true,
            message: 'Please select an artist',
            trigger: 'change',
          },
        ],
        autographUrl: [
          {
            required: true,
            message: 'Please upload photo of autograph',
            trigger: 'change',
          },
          {
            message: 'File size must less than 25mb',
            validator: validateUploadFile,
          },
        ],
        autographProofUrl: [
          {
            required: true,
            message: 'Please upload proof of autograph',
            trigger: 'change',
          },
          {
            message: 'File size must less than 25mb',
            validator: validateUploadFile,
          },
        ],
      },
    };
  },
};
</script>

<style lang="scss">
.el-dialog {
  background: black !important;

  .el-icon-close {
    font-size: 30px;
    color: white !important;
    font-weight: bold;
  }
}

.discard-btn {
  color: #FF0000;
  font-weight: bold;
  margin-right: 50px;
  font-size: 18px;

  &:hover,
  &:active,
  &:focus {
    color: #FF0000;
  }
}

.image {
  width        : auto;
  height       : auto;
  max-height   : 100%;
  max-width    : 100%;
  border-radius: var(--el-input-border-radius,var(--el-border-radius-base));
  object-fit   : contain;
}
</style>
