<template>
  <FullPageLoader v-if="isLoggingIn"/>
  <div class="landing-page min-h-screen" v-else>
    <div class="py-6 text-4xl md:text-8xl font-gotham">
      <router-link to="/">XM</router-link>
    </div>
    <div class="mt-28 md:mt-6 flex flex-col md:flex-row justify-center gap-28 pl-3 pr-3 md:px-0">
      <!-- explore -->
      <div class="explore-box pl-3 pr-3 md:px-16 py-14 border-1">
        <div class="flex flex-col">
          <div class="text-2xl md:text-4xl mb-6 md:mb-14 font-gotham">Explore Statue Profiles</div>
          <div class="relative">
            <div class="explore-input search">
              <div class="flex">
                <img class="px-3" src="../assets/images/search-icon.png" alt="">
                <input :value="searchKey" class="flex-auto" type="text" placeholder="Serial number or character name"
                  v-debounce:400ms="onSearchTyping"
                  @input="onSearchInput"
                  @keydown.enter="searchStatueProfiles">
                <img class="px-3 cursor-pointer" src="../assets/images/circle-x-icon.png" alt=""
                  @click="clearSearchField">
                <div class="explore-input-label">Search</div>
              </div>
              <div ref="searchList" class="pt-3 search-list bg-black flex flex-col gap-2 text-gray"
                :class="statueProfiles.length > 0 ? 'flex' : 'hidden'">
                <router-link :to="`/productprovenance?id=${statueProfile.serialNumber}`"
                  v-for="statueProfile in statueProfiles" :key="statueProfile">
                  <div class="text-start px-14">
                    <div class="text-base">{{ statueProfile.product.productName }}</div>
                    <div class="text-xs">{{ statueProfile.serialNumber }}</div>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
          <div class="mt-14">
            <div class="mt-1 pl-4 text-start text-xs text-red"
              v-if="statueProfiles.length === 0 && isSearchFinished">Serial number not found.</div>
            <div class="flex justify-end" :class="statueProfiles.length === 0 && isSearchFinished ? 'pt-1' : 'pt-6'">
              <div class="explore-button" @click="searchStatueProfiles">{{ isSearching ? `Searching...` : `Search` }}</div>
            </div>
          </div>
        </div>
      </div>
      <!-- login -->
      <div class="explore-box px-10 md:px-16 py-14">
        <div class="flex flex-col">
          <div class="text-4xl font-gotham" v-if="!isLoggedIn">Log In</div>
          <div class="text-4xl font-gotham" v-else>Welcome, {{ currentUserName }}</div>
          <div class="mt-6 flex flex-col gap-6" v-if="!isLoggedIn">
            <div class="explore-input relative flex pl-4 md:pl-14">
              <input ref="usernameField" class="flex-auto" type="text" placeholder="Enter username"
                @keyup.enter="login(this.$refs.usernameField.value, this.$refs.passwordField.value)">
              <img class="px-3 cursor-pointer" src="../assets/images/circle-x-icon.png" alt=""
                @click="clearUsernameField">
              <div class="explore-input-label">Username</div>
            </div>
            <div class="explore-input relative flex pl-4 md:pl-14">
              <input ref="passwordField" class="flex-auto" type="password" placeholder="Enter password"
                @keyup.enter="login(this.$refs.usernameField.value, this.$refs.passwordField.value)">
              <img class="px-3 cursor-pointer" src="../assets/images/circle-x-icon.png" alt=""
                @click="clearPasswordField">
              <div class="explore-input-label">Password</div>
            </div>
          </div>
          <div class="mt-1 pl-4 text-start text-xs text-red" v-if="userNotFoundFlag">User not found.</div>
          <div class="flex justify-end" :class="userNotFoundFlag ? 'mt-1' : 'mt-6'" v-if="!isLoggedIn">
            <div v-if="!isLoggingIn" class="explore-button cursor-pointer"
              @click="login(this.$refs.usernameField.value, this.$refs.passwordField.value)">
              Log In
            </div>
            <div v-else class="explore-button cursor-pointer">
              Logging in...
            </div>
          </div>
          <div class="flex justify-center gap-6 mt-6" v-else>
            <router-link to="/profile">
              <div class="explore-button cursor-pointer">
                Profile
              </div>
            </router-link>
            <div class="explore-button cursor-pointer" @click="logout">
              Sign out
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="py-28">
      <div class="text-2xl font-gotham w-64 md:w-auto mx-auto">Want to make your collectible’s profile your own?</div>
      <div class="mt-4 text-base">
        Customize your collectible profiles with photos, videos and more, <br class="hidden md:block">
        to document the unique experiences on your collectibles journey!
      </div>
      <div class="pt-12 package-list flex flex-col md:flex-row gap-10 md:gap-16 justify-center">
        <div class="flex flex-col justify-center gap-10" v-for="packageItem in packages" :key="packageItem"
          :class="userPackage !== null && userPackage.collectibleProfilePackageId === packageItem.collectibleProfilePackageId ?
          'opacity-70' : 'opacity-100'">
          <div class="package text-center px-7 py-9 mx-auto">
            <div class="text-2xl font-gotham">{{ packageItem.packageName }}</div>
            <div class="mt-2 text-xl font-semibold">USD{{ packageItem.monthPrice }}/month</div>
            <div class="text-base font-semibold">USD{{ packageItem.yearPrice }}/year</div>
            <div class="mt-6 text-xl">
              • {{ packageItem.numOfProfiles }} statue profiles <br>
              • {{ packageItem.numOfProfiles * packageItem.sizePerProfile }}MB total ({{ packageItem.sizePerProfile }}MB/profile)
            </div>
          </div>
          <div
            v-if="userPackage !== null && userPackage.collectibleProfilePackageId === packageItem.collectibleProfilePackageId">
            <div class="package-button cursor-pointer">
              Currently active
            </div>
            <div class="mt-2 text-xs">Plan ends on: {{ moment(userPackage.subscriptionEndDate).format('L') }}</div>
          </div>
          <div v-else>
            <div class="package-button cursor-pointer"
              @click="subscribeOrUpgradePackage(packageItem.collectibleProfilePackageId)">
              Get {{ packageItem.packageName }}
            </div>
            <div class="mt-2 text-xs invisible">Plan ends on: {{ userPackage.subscriptionEndDate }}</div>
          </div>
        </div>
      </div>
      <div class="pt-12">
        <div class="text-2xl font-gotham">Need more?</div>
        <div class="mt-10 package-button">Contact Us!</div>
      </div>
    </div>
    <LoginModal
      :modalClose="closeLoginModal"
      :modalStatus="isLoginModal"
      :isLoggingIn="isLoggingIn"
    />
  </div>
</template>

<script>
import { onMounted, ref } from 'vue';
import { vue3Debounce } from 'vue-debounce';
import moment from 'moment';
import axios from 'axios';
import { xmAxios } from '@/plugins/axios';
import LoginModal from '@/components/LoginModal.vue';
import FullPageLoader from '@/components/Share/FullPageLoader.vue';

export default {
  directives: {
    debounce: vue3Debounce({
      lock: true,
    }),
  },
  components: {
    LoginModal,
    FullPageLoader,
  },
  data() {
    const searchKey = ref('');
    const isSearchActive = ref(false);
    const isSearchFinished = ref(false);
    const packages = ref([]);
    const isLoginModal = ref(false);
    const isLoggingIn = ref(false);
    const isSearching = ref(false);
    const statueProfiles = ref([]);
    const isLoggedIn = ref(false);
    const currentUserName = ref('');
    const userPackage = ref([]);

    const clearSearchField = () => {
      statueProfiles.value = [];
      searchKey.value = '';
      isSearchActive.value = false;
      isSearchFinished.value = false;
      // this.$refs.searchList.classList.add('hidden');
    };
    const userNotFoundFlag = ref(false);
    const clearUsernameField = () => {
      this.$refs.usernameField.value = '';
      userNotFoundFlag.value = false;
    };
    const clearPasswordField = () => {
      this.$refs.passwordField.value = '';
      userNotFoundFlag.value = false;
    };
    const searchStatueProfiles = async () => {
      isSearching.value = true;
      const searchUrl = `${process.env.VUE_APP_ROUTER_DOMAIN}/api/xm/product/v1/searchproductserialnumber/${searchKey.value}`;
      await axios
        .get(searchUrl)
        .then((res) => {
          isSearchFinished.value = true;
          // console.log(res.data);
          statueProfiles.value = res.data;
          isSearchActive.value = res.data.length > 0;
          isSearching.value = false;
        })
        .catch(() => {
          isSearchFinished.value = true;
          isSearchActive.value = false;
          isSearching.value = false;
        });
    };
    const onSearchInput = async (event) => {
      searchKey.value = event.target.value;
    };
    const onSearchTyping = async () => {
      // console.log(searchKey.value);
      searchStatueProfiles();
    };
    const onFocusOut = () => {
      this.isSearchActive = false;
    };
    const login = async (userName, secret) => {
      isLoggingIn.value = true;
      const loginUrl = `${process.env.VUE_APP_GENERIC_ROUTER_DOMAIN}/api/user/v1/auth`;
      await axios
        .post(loginUrl, {
          userName,
          secret,
          applicationDomain: 'xm',
        })
        .then((res) => {
          if (res.data.token) {
            // save token and user id to local storage
            localStorage.setItem('token', res.data.token);
            localStorage.setItem('user_uuid', res.data.userId);
            localStorage.setItem('user_name', userName);
            isLoggingIn.value = false;
            this.$router.push('profile');
          }
        })
        .catch(() => {
          userNotFoundFlag.value = true;
          isLoggingIn.value = false;
        });
    };
    const subscribeOrUpgradePackage = async (packageId) => {
      if (localStorage.getItem('user_uuid') === null) {
        this.openLoginModal();
      } else if (userPackage.value.length !== 0) {
        const changeSubscriptionUrl = `${process.env.VUE_APP_ROUTER_DOMAIN}/api/xm/product/v1/changeSubscription`;
        await xmAxios.post(changeSubscriptionUrl, {
          packageId,
        }).then((response) => {
          console.log(response.data);
          this.$router.push({
            name: 'Payment Success',
          });
        });
      } else {
        isLoggingIn.value = true;
        const startTransaction = `${process.env.VUE_APP_ROUTER_DOMAIN}/api/xm/product/v1/starttransaction/${packageId}`;
        await xmAxios.post(startTransaction).then((response) => {
          if (response.data) {
            console.log(response.data);
            window.location.href = response.data.paymentUrl;
          }
        });
      }
    };
    const openLoginModal = () => {
      isLoginModal.value = true;
      document.body.classList.add('modal-open');
    };

    const closeLoginModal = () => {
      isLoginModal.value = false;
      document.body.classList.remove('modal-open');
    };

    const logout = () => {
      localStorage.removeItem('token');
      localStorage.removeItem('user_uuid');
      localStorage.removeItem('user_name');
      this.$router.go();
    };
    onMounted(async () => {
      // get logged in user uuid
      isLoggedIn.value = localStorage.getItem('user_uuid') !== null;
      currentUserName.value = localStorage.getItem('user_name');
      // get list of packages
      const getPackagesUrl = `${process.env.VUE_APP_ROUTER_DOMAIN}/api/xm/product/v1/getpackages`;
      axios.get(getPackagesUrl).then((response) => {
        // console.log(response.data);
        packages.value = response.data;
      });
      // get user package
      if (isLoggedIn.value === true) {
        const getSubscriptionStatusUrl = `${process.env.VUE_APP_ROUTER_DOMAIN}/api/xm/product/v1/getSubscriptionStatus`;
        await xmAxios.get(getSubscriptionStatusUrl).then(async (response) => {
          // userPackage.value = packages.value.filter((e) => e.collectibleProfilePackageId === response.data.collectibleProfilePackageId);
          userPackage.value = response.data;
        });
      }
    });
    return {
      moment,
      statueProfiles,
      searchKey,
      isSearchActive,
      isSearchFinished,
      isSearching,
      userNotFoundFlag,
      packages,
      isLoginModal,
      isLoggingIn,
      isLoggedIn,
      currentUserName,
      userPackage,
      clearUsernameField,
      clearPasswordField,
      clearSearchField,
      searchStatueProfiles,
      onSearchInput,
      onSearchTyping,
      onFocusOut,
      login,
      subscribeOrUpgradePackage,
      openLoginModal,
      closeLoginModal,
      logout,
    };
  },
};
</script>

<style lang="scss">
.font-gotham {
  font-family: 'gotham';
}
.landing-page {
  background: black;
  color: white;
  a[class^=router-link] {
    color: white;
  }
}
.explore-box {
  width: 580px;
  max-height: 376px;
}
@media (max-width: 768px) {
  .explore-box {
    width: 100%;
  }
  .explore-input.search {
    .search-list {
      height: 232px;
      overflow: auto;
    }
  }
}
.explore-input {
  position: relative;
  border: 1px solid #FFFFFF;
  border-radius: 4px;
  padding: 16px 0;
  background: black;
  &.search {
    position: absolute !important;
    // width: -webkit-fill-available;
    width: 100%;
    z-index: 0;
  }
  input {
    background: none !important;
    border: none !important;
    outline: none !important;
  }
  .explore-input-label {
    position: absolute;
    top: 0;
    left: 16px;
    transform: translateY(-50%);
    background: black;
    padding: 0 4px;
    font-size: 12px;
  }
  .search-list {
    color: #828089;
    a:hover {
      color: white;
    }
  }
}
.explore-button {
  font-family: 'gotham';
  width: 114px;
  border: 1px solid #FFFFFF;
  padding: 8px 0;
  cursor: pointer;
  font-size: 12px;
  color: white !important;
}
.package-list {
  .package {
    width: 221px;
    height: 293px;
    background-image: url('../assets/images/explore/package-border.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
}
.package-button {
  font-family: 'gotham';
  font-size: 16px;
  cursor: pointer;
  width: 180px;
  margin: auto;
  padding: 12px 0;
  border: 1px solid #FFFFFF;
}
</style>
