<template>
  <el-dialog v-model="showModal" width="30%" fullscreen :destroy-on-close="true">
    <el-row :gutter="20" justify="center" style="text-align: left;">
      <el-col :span="12" :xs="24">
        <el-row :gutter="20" class="mb-3 pe-3" v-if="isApproved" style="text-align: center;">
          <el-col :span="24">
            <h3>Approved by XM Team</h3>
          </el-col>
        </el-row>
        <el-row :gutter="20" class="mb-3 pe-3" v-if="isWaitingApprove" style="text-align: center;">
          <el-col :span="24">
            <h4>Awaiting approval by XM Team</h4>
          </el-col>
        </el-row>
        <el-row :gutter="20" class="mb-3">
          <el-col :span="24" :sm="12" class="mb-3 mb-sm-3 mb-md-0">
            <div class="label mb-1">Artist Name</div>
            <div class="ms-1">{{ artistName }}</div>
          </el-col>
          <el-col :span="24" :sm="12">
            <div class="label mb-1">Date of Autograph</div>
            <div class="ms-1">{{ autographDate }}</div>
          </el-col>
        </el-row>
        <el-row :gutter="20" class="mb-3">
          <el-col :span="24">
            <div class="label mb-1">Name of Event</div>
            <div class="ms-1">{{ currentAutograph.eventName }}</div>
          </el-col>
        </el-row>
        <el-row :gutter="20" class="mb-3">
          <el-col :span="24">
            <div class="label mb-1">Uploaded autograph</div>
            <div class="media-container">
              <img class="image" :src="currentAutograph.productAutographUrl" />
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <div class="label mb-1">Uploaded image/video proof</div>
            <div class="media-container">
              <img class="image" v-if="isImgage" :src="currentAutograph.productAutographProofUrl" />
              <video class="image" v-else loop muted controls>
                <source :src="currentAutograph.productAutographProofUrl" />Your browser does not support HTML video.
              </video>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="30" class="mt-5" style="text-align: right">
          <el-col :span="24">
            <!-- <el-popconfirm
              popper-class="custom-popper customer-popconfirm-popper"
              title="Are you sure to delete this autograph?"
              confirmButtonType="danger"
              @confirm="deletePendingAutograph"
              v-if="isWaitingApprove && isOwner"
            >
              <template #reference>
                <el-button
                  class="discard-btn"
                  type="text"
                  :disabled="deleting"
                >{{ deleting ? 'Deleting' : 'Delete' }}</el-button>
              </template>
            </el-popconfirm> -->
            <div class="w-full flex justify-center md:justify-between">
              <el-button
                class="custom-btn white-border-btn hidden md:block"
                plain
                @click="modalClose"
              >Back</el-button>
              <div class="flex gap-6 flex-col md:flex-row" v-if="isWaitingApprove && isOwner">
                <el-button
                  class="custom-btn2 text-red"
                  @click="openDeleteEntryModal"
                >Delete</el-button>
                <el-button
                  class="custom-btn2"
                >Edit</el-button>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <DeleteEntryModal
      :modalClose="closeDeleteEntryModal"
      :modalStatus="isDeleteEntryModal"
      :productAutographId="this.currentAutograph.productAutographId"
    />
  </el-dialog>
</template>

<script>
import { ref } from 'vue';
import axios from 'axios';
import DeleteEntryModal from '@/components/DeleteEntryModal.vue';
import { IMAGE_FORMAT, FILE_SUPPORTED_FORMATS } from '../common/constants';

export default {
  components: {
    DeleteEntryModal,
  },
  props: {
    modalClose: {
      type: Function,
      required: true,
    },
    modalStatus: {
      type: Boolean,
      required: true,
    },
    currentAutograph: {
      type: Object,
      required: true,
    },
    artistName: {
      type: String,
      required: true,
    },
    autographDate: {
      type: String,
      required: true,
    },
    isOwner: {
      type: Boolean,
      required: true,
    },
  },
  watch: {
    modalStatus(newModalStatus) {
      this.showModal = newModalStatus;
    },
    showModal(newShowModal) {
      if (!newShowModal) {
        this.modalClose();
      }
    },
  },
  computed: {
    isImgage() {
      return IMAGE_FORMAT.some((x) => x === this.currentAutograph.productAutographProofType);
    },
    isWaitingApprove() {
      return this.currentAutograph.productAutographStatus.toLowerCase() === 'pending';
    },
    isApproved() {
      return this.currentAutograph.productAutographStatus.toLowerCase() === 'approved';
    },
  },
  methods: {
    deletePendingAutograph() {
      if (this.isOwner) {
        this.deleting = true;
        const apiUrl = `${process.env.VUE_APP_ROUTER_DOMAIN}/api/xm/product/v1/productautograph/${this.currentAutograph.productAutographId}`;
        axios.delete(apiUrl).then(() => {
          window.location.reload();
        });
      }
    },
  },
  data() {
    const isDeleteEntryModal = ref(false);

    const openDeleteEntryModal = () => {
      isDeleteEntryModal.value = true;
      document.body.classList.add('modal-open');
    };

    const closeDeleteEntryModal = () => {
      isDeleteEntryModal.value = false;
      document.body.classList.remove('modal-open');
    };
    return {
      showModal: this.modalStatus,
      imgFileFormat: IMAGE_FORMAT.join(','),
      mediaFileFormat: FILE_SUPPORTED_FORMATS.join(','),
      deleting: false,
      isDeleteEntryModal,
      openDeleteEntryModal,
      closeDeleteEntryModal,
    };
  },
};
</script>

<style lang="scss">
.el-dialog {
  background: black !important;

  .el-icon-close {
    font-size: 30px;
    color: white !important;
    font-weight: bold;
  }
}

.el-form-item__label,
.el-dialog__body {
  color: white !important;
}

.image {
  width: auto;
  height: auto;
  max-height: 100%;
  max-width: 100%;
  border-radius: var(--el-input-border-radius, var(--el-border-radius-base));
  object-fit: contain;
}

.label {
  font-family: 'gotham';
  text-transform: uppercase;
  font-size: 14px;
}
</style>
