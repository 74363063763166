<template>
  <div class="payment-page min-h-screen">
    <div class="flex gap-20 flex-col min-h-screen justify-center">
      <div class="text-4xl font-gotham">Payment Success!</div>
      <div class="">
        Congrats! <br>
        You can now customise your statue profiles.
        <br><br>
        Visit your Profile page to see how many statues you can <br>
        customise with your package.
      </div>
      <router-link to="/profile">
        <div class="px-3 py-2 font-gotham text-xl cursor-pointer">GO TO PROFILE</div>
      </router-link>
    </div>
  </div>
</template>

<style lang="scss">
.font-gotham {
  font-family: 'gotham';
}
.payment-page {
  background: black;
  color: white;
  .container {
    max-width: 868px;
  }
  a {
    color: white;
  }
}
</style>
