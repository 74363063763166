<template>
  <Carousel :settings="carouselSetting" class="autograph-carousel">
    <Slide v-for="autograph in autographs" :key="autograph.productAutographId">
      <div class="carousel__item">
        <div class="autograph-container">
          <div class="pending-overlay" v-if="isWaitingApprove">
            <div>Pending Approval</div>
          </div>
          <el-row :gutter="20">
            <el-col :md="12" :xs="24" :sm="24">
              <div class="media-container">
                <img :src="autograph.productAutographUrl" />
              </div>
            </el-col>
            <el-col :md="12" :xs="24" :sm="24">
              <div class="media-container">
                <img v-if="isImgage" :src="autograph.productAutographProofUrl" />
                <video class="image" v-else autoplay loop muted controls>
                  <source :src="autograph.productAutographProofUrl" />
                  Your browser does not support HTML video.
                </video>
              </div>
            </el-col>
          </el-row>
          <div class="mt-2" style="font-size: 15px;">
            <div class="fw-bold">{{ artistName }}</div>
            <div class="fst-italic">{{ autographDate }}</div>
            <div class="fst-italic">{{ autographs[currentSlide].eventName }}</div>
          </div>
        </div>
      </div>
    </Slide>

    <template #addons="{ currentSlide }">
      {{ slideOnChange(currentSlide) }}
      <Pagination />
    </template>
  </Carousel>

  <!-- <div class="mt-4">{{ designs[currentSlide].designConcept }}</div> -->
  <div class="flex justify-center gap-6 pt-4 pb-20">
    <el-button plain class="custom-btn white-border-btn" @click="openViewAutographModal">View</el-button>
    <el-button plain class="custom-btn white-border-btn" v-if="isOwner" @click="openAutographModal">Upload</el-button>
  </div>

  <ViewAutograpModal
    :modalClose="closeViewAutographModal"
    :modalStatus="isViewAutograph"
    :currentAutograph="autographs[currentSlide]"
    :artistName="artistName"
    :autographDate="autographDate"
    :isOwner="isOwner"
  />
  <AddAutographModal
    :modalClose="closeAutographModal"
    :productArtists="productArtists"
    :modalStatus="isAddAutograph"
    :serialNumberId="serialNumberId"
  />
</template>

<script>
import 'vue3-carousel/dist/carousel.css';
import { DateTime } from 'luxon';
import { ref, computed } from 'vue';
import { Carousel, Slide, Pagination } from 'vue3-carousel';
import { IMAGE_FORMAT } from '../common/constants';
import ViewAutograpModal from './ViewAutograpModal.vue';
import AddAutographModal from './AddAutographModal.vue';

export default {
  components: {
    Carousel,
    Slide,
    Pagination,
    ViewAutograpModal,
    AddAutographModal,
  },
  props: {
    autographs: {
      type: Array,
      required: true,
    },
    productArtists: {
      type: Array,
      required: true,
    },
    isOwner: {
      type: Boolean,
      required: true,
    },
    serialNumberId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const carouselSetting = ref({
      itemsToShow: 1,
      wrapAround: props.autographs.length > 2 ?? true,
      mouseDrag: true,
      touchDrag: true,
    });

    const currentSlide = ref(0);

    const slideOnChange = (index) => {
      currentSlide.value = index;
    };

    const isImgage = computed(() => (
      IMAGE_FORMAT.some((x) => x === props.autographs[currentSlide.value].productAutographProofType)
    ));

    const artistName = computed(() => {
      const findArtist = props.productArtists.find((x) => x.artistId === props.autographs[currentSlide.value].autographArtistId);
      return findArtist.artistName;
    });

    const autographDate = computed(() => (
      DateTime.fromISO(props.autographs[currentSlide.value].autographDate).toFormat('dd/MM/yyyy')
    ));

    const isWaitingApprove = computed(() => (
      props.autographs[currentSlide.value].productAutographStatus.toLowerCase() === 'pending'
    ));

    const isViewAutograph = ref(false);

    const openViewAutographModal = () => {
      isViewAutograph.value = true;
    };

    const closeViewAutographModal = () => {
      isViewAutograph.value = false;
    };
    const isAddAutograph = ref(false);

    const openAutographModal = () => {
      isAddAutograph.value = true;
    };

    const closeAutographModal = () => {
      isAddAutograph.value = false;
    };

    return {
      carouselSetting,
      currentSlide,
      slideOnChange,
      isImgage,
      artistName,
      autographDate,
      isWaitingApprove,
      isViewAutograph,
      isAddAutograph,
      openViewAutographModal,
      closeViewAutographModal,
      openAutographModal,
      closeAutographModal,
    };
  },
};
</script>

<style lang="scss">
.autograph-carousel {
  .carousel__slide {
    padding: unset !important;
  }

  .carousel__item {
    height: 680px;
    cursor: unset !important;
  }

  .carousel__pagination {
    padding-inline-start: 0 !important;
  }
}

.autograph-container {
  width: 100%;
  height: calc(100% - 65px);
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

@media screen and (min-width: 992px) {
  .autograph-carousel {
    .carousel__item {
      height: 400px;
    }
  }

  .autograph-container {
    height: calc(100% - 40px);
  }
}

.media-container {
  height: 250px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pending-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  // background: rgba(255,255,255,0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--el-input-border-radius, var(--el-border-radius-base));

  +.el-row {
    opacity: 0.5;
  }

  div {
    font-family: 'gotham';
    text-transform: uppercase;
    color: #FF0000;
    font-size: 36px;
    user-select: none;
  }
}
</style>
