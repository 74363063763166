<template>
  <div id="mynetwork" :style="{ height: `calc(100% - ${headerHeight}px)` }"></div>
</template>

<script>
import { Network } from 'vis-network';
import axios from 'axios';
import 'vis-network/styles/vis-network.css';
import { VIS_OPTION } from '../common/constants';

export default {
  props: {
    characterId: {
      type: String,
      required: true,
    },
    headerHeight: {
      type: Number,
      default: 0,
    },
  },
  async mounted() {
    const characterLibraryUrl = `${process.env.VUE_APP_ROUTER_DOMAIN}/api/xm/product/v1/characterlibrary/${this.characterId}`;
    let libraryData = null;
    await axios.get(characterLibraryUrl).then((res) => {
      libraryData = res.data;
    });

    const nodesData = [];
    const edgesData = [];

    libraryData.forEach((data) => {
      const nodeObj = {
        id: data.character.characterId,
        label: data.character.characterName,
        shape: 'box',
        font: {
          size: 30,
          color: '#ffffff',
        },
        borderWidth: data.character.characterId === this.characterId.toLowerCase() ? 5 : 1,
      };
      nodesData.push(nodeObj);
      data.relatedCharacter.forEach((relatedCharacter) => {
        const edgeObj = {
          from: data.character.characterId,
          to: relatedCharacter.relatedCharacter.characterId,
          label: relatedCharacter.relation,
        };
        edgesData.push(edgeObj);
      });
    });

    // create a network
    const container = document.getElementById('mynetwork');
    const data = {
      nodes: nodesData,
      edges: edgesData,
    };
    const options = {
      ...VIS_OPTION,
    };
    options.nodes.color.highlight.background = '#000';
    options.layout.randomSeed = this.characterId;

    const network = new Network(container, data, options);
    network.on('click', () => {
      // const ids = properties.nodes;
      // console.log(ids);
    });
  },
};
</script>
