<template>
  <Transition>
  <div class="delete-entries-modal" v-if="modalStatus">
    <div class="modal-container m-auto flex flex-col justify-center">
      <div class="body mx-auto">
        <div class="text-red text-2xl md:text-4xl font-gotham">Delete all entries from this item?</div>
        <div class="text-start text-base mt-4 md:t-14">
          Are you sure you want to delete all user-uploaded entries
          from this item? This will free up 1 quota from your current
          quota used and storage used. <br>
          <br>
          This action cannot be undone. Do you want to proceed?
        </div>
        <div class="mt-20 flex gap-6 md:gap-0 flex-col md:flex-row justify-between">
          <div class="submit-button px-3 py-2 font-gotham text-xl cursor-pointer order-2 md:order-1" @click="modalClose">CANCEL</div>
          <div class="submit-button px-3 py-2 font-gotham text-xl text-red cursor-pointer order-1 md:order-2" @click="deleteAllEntries">DELETE</div>
        </div>
      </div>
    </div>
  </div>
  </Transition>
</template>

<script>
import { xmAxios } from '@/plugins/axios';

export default {
  props: {
    modalClose: {
      type: Function,
      required: true,
    },
    modalStatus: {
      type: Boolean,
      required: true,
    },
    productId: {
      type: String,
      required: true,
    },
  },
  watch: {
    modalStatus(newModalStatus) {
      this.showModal = newModalStatus;
    },
    showModal(newShowModal) {
      if (!newShowModal) {
        this.modalClose();
      }
    },
  },
  data(props) {
    const deleteAllEntries = () => {
      const productAutographArchiveUrl = `${process.env.VUE_APP_ROUTER_DOMAIN}/api/xm/product/v1/productautograph/archive/${props.productId}`;
      xmAxios.get(productAutographArchiveUrl).then((response) => {
        console.log(response);
      });
    };
    return {
      deleteAllEntries,
    };
  },
};
</script>
<style lang="scss">
.font-gotham {
  font-family: 'gotham';
}
.delete-entries-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #000000e6;
  color: white;
  position: fixed;
  top: 0;
  z-index: 1;
  width: -webkit-fill-available;
  min-height: 100vh;
  .modal-container {
    background: black;
    width: 1375px;
    height: 735px;
    border-radius: 30px;
    .body {
      width: 664px;
      height: fit-content;
    }
  }
}
@media (max-width: 768px) {
  .delete-entries-modal {
    .modal-container {
      width: 100% !important;
      .body {
        width: 100% !important;
        padding: 0 38px;
      }
    }
  }
}
.package-list {
  .package {
    width: 221px;
    height: 293px;
    background-image: url('../assets/images/explore/package-border.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
}
.package-button {
  font-family: 'gotham';
  font-size: 16px;
  cursor: pointer;
  width: 180px;
  margin: auto;
  padding: 12px 0;
  border: 1px solid #FFFFFF;
}
// Transition Styles
.v-enter-active,
.v-leave-active {
  transition: all 0.2s ease-in-out;
}

.v-enter-from,
.v-leave-to {
  transform: translateY(-15px);
  opacity: 0;
}
</style>
