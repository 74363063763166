<template>
  <div id="mynetwork" :style="{ height: `calc(100% - ${headerHeight}px)` }"></div>
</template>

<script>
import { Network } from 'vis-network';
import axios from 'axios';
import 'vis-network/styles/vis-network.css';
import { VIS_OPTION } from '../common/constants';

export default {
  props: {
    characterId: {
      type: String,
      required: true,
    },
    productId: {
      type: String,
      required: true,
    },
    headerHeight: {
      type: Number,
      default: 0,
    },
  },
  async mounted() {
    const characterProductsUrl = `${process.env.VUE_APP_ROUTER_DOMAIN}/api/xm/product/v1/${this.characterId}/character/products`;
    let libraryData = null;
    await axios.get(characterProductsUrl).then((res) => {
      libraryData = res.data;
    });

    const nodesData = [];
    const edgesData = [];

    libraryData.forEach((data) => {
      const nodeObj = {
        id: data.productId,
        title: data.productName,
        shape: 'circularImage',
        image: data.productDisplayImage,
        font: {
          color: '#ffffff',
        },
        borderWidth: data.productId === this.productId.toLowerCase() ? 15 : 1,
      };
      nodesData.push(nodeObj);

      libraryData.forEach((data2) => {
        if (data2.productId !== data.productId) {
          const edgeObj = {
            from: data.productId,
            to: data2.productId,
            label: '',
            color: '#000000',
          };
          edgesData.push(edgeObj);
        }
      });
    });

    // create a network
    const container = document.getElementById('mynetwork');
    const data = {
      nodes: nodesData,
      edges: edgesData,
    };
    const options = {
      ...VIS_OPTION,
      interaction: {
        dragNodes: false,
        dragView: true,
        zoomView: true,
        selectable: false,
      },
    };

    const network = new Network(container, data, options);
    network.on('click', () => {
      // const ids = properties.nodes;
      // console.log(ids);
    });
  },
};
</script>
