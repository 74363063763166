<template>
  <Transition>
  <div class="login-modal flex justify-center" v-if="modalStatus">
    <!-- login -->
    <div class="login-box my-auto px-10 md:px-0">
      <div class="flex flex-col">
        <div class="text-4xl font-gotham">Log In</div>
        <div class="mt-2 text-xl">Please log in to purchase a package.</div>
        <div class="mt-12 flex flex-col gap-6">
          <div class="login-input relative flex pl-14">
            <input ref="usernameField" class="flex-auto" type="text" placeholder="Enter username"
              @keyup.enter="login(this.$refs.usernameField.value, this.$refs.passwordField.value)">
            <img class="px-3 cursor-pointer" src="../assets/images/circle-x-icon.png" alt=""
              @click="clearUsernameField">
            <div class="login-input-label">Username</div>
          </div>
          <div class="login-input relative flex pl-14">
            <input ref="passwordField" class="flex-auto" type="password" placeholder="Enter password"
              @keyup.enter="login(this.$refs.usernameField.value, this.$refs.passwordField.value)">
            <img class="px-3 cursor-pointer" src="../assets/images/circle-x-icon.png" alt=""
              @click="clearPasswordField">
            <div class="login-input-label">Password</div>
          </div>
        </div>
        <div class="mt-1 pl-4 text-start text-xs text-red" v-if="userNotFoundFlag">User not found.</div>
        <div class="flex justify-between md:justify-end" :class="userNotFoundFlag ? 'mt-1' : 'mt-6'">
          <div class="login-button border-0 cursor-pointer block md:hidden"
            @click="modalClose">
            Back
          </div>
          <div class="login-button cursor-pointer"
            @click="login(this.$refs.usernameField.value, this.$refs.passwordField.value)">
            Log In
          </div>
        </div>
      </div>
    </div>
    <a href="javascript:void" @click="modalClose">
      <img src="@/assets/images/close-icon.png" alt="" class="absolute top-12 right-14 hidden md:block">
    </a>
    <div class="absolute top-4 mx-auto font-gotham text-4xl block md:hidden">XM</div>
  </div>
  </Transition>
</template>

<script>
import { ref } from 'vue';
import axios from 'axios';

export default {
  props: {
    modalClose: {
      type: Function,
      required: true,
    },
    modalStatus: {
      type: Boolean,
      required: true,
    },
  },
  watch: {
    modalStatus(newModalStatus) {
      this.showModal = newModalStatus;
    },
    showModal(newShowModal) {
      if (!newShowModal) {
        this.modalClose();
      }
    },
  },
  data() {
    const userNotFoundFlag = ref(false);
    const clearUsernameField = () => {
      this.$refs.usernameField.value = '';
      userNotFoundFlag.value = false;
    };
    const clearPasswordField = () => {
      this.$refs.passwordField.value = '';
      userNotFoundFlag.value = false;
    };
    const login = async (userName, secret) => {
      const loginUrl = `${process.env.VUE_APP_GENERIC_ROUTER_DOMAIN}/api/user/v1/auth`;
      await axios
        .post(loginUrl, {
          userName,
          secret,
          applicationDomain: 'xm',
        })
        .then((res) => {
          if (res.data.token) {
            // save token and user id to local storage
            localStorage.setItem('token', res.data.token);
            localStorage.setItem('user_uuid', res.data.userId);
            localStorage.setItem('user_name', userName);
            document.body.classList.remove('modal-open');
            this.$router.push('profile');
          }
        })
        .catch(() => {
          userNotFoundFlag.value = true;
        });
    };
    return {
      userNotFoundFlag,
      clearUsernameField,
      clearPasswordField,
      login,
    };
  },
};
</script>

<style lang="scss">
.font-gotham {
  font-family: 'gotham';
}
.login-modal {
  background: black;
  color: white;
  position: fixed;
  top: 0;
  z-index: 1;
  width: -webkit-fill-available;
  min-height: 100vh;
  .container {
    max-width: 868px;
  }
}
.login-box {
  width: 722px;
  max-height: 376px;
}
.login-input {
  border: 1px solid #FFFFFF;
  border-radius: 4px;
  padding: 16px 0;
  padding-left: 16px;
  input {
    background: none !important;
    border: none !important;
    outline: none !important;
  }
  .login-input-label {
    position: absolute;
    top: 0;
    left: 16px;
    transform: translateY(-50%);
    background: black;
    padding: 0 4px;
    font-size: 12px;
  }
}
.login-button {
  font-family: 'gotham';
  width: 114px;
  border: 1px solid #FFFFFF;
  padding: 8px 0;
  cursor: pointer;
  font-size: 12px;
  color: white !important;
}
// Transition Styles
.v-enter-active,
.v-leave-active {
  transition: all 0.2s ease-in-out;
}

.v-enter-from,
.v-leave-to {
  transform: translateY(-15px);
  opacity: 0;
}
</style>
