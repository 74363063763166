<template>
  <FullPageLoader v-if="isTransactionLoading"/>
  <div class="profile-page min-h-screen" v-else>
    <div class="py-6 text-5xl flex justify-between container">
      <div class="cursor-pointer flex items-center invisible">
        <img src="@/assets/images/search-icon-white.png" alt="">
      </div>
      <router-link class="router-link font-gotham" to="/">XM</router-link>
      <div class="flex items-center md:relative">
         <img class="cursor-pointer hidden md:block" src="@/assets/images/search-icon-white.png" alt=""
         @click="openFloatingSearchBar">
         <img class="cursor-pointer block md:hidden" src="@/assets/images/search-icon-white.png" alt=""
         @click="openFloatingSearchBarMobile">
         <div class="explore-container fixed md:absolute top-1/2 md:top-12 right-0 hidden md:block">
          <div class="explore-box-profile bg-black px-6 py-8 border-1 border-white"
            v-click-outside="closeFloatingSearchBar"
            v-if="isFloatingSearchBar">
            <div class="flex flex-col">
              <div class="relative">
                <div class="explore-input search">
                  <div class="flex">
                    <img class="px-3" src="../assets/images/search-icon.png" alt="">
                    <input :value="searchKey" class="flex-auto" type="text" placeholder="Serial number or character name"
                      v-debounce:400ms="onSearchTyping"
                      @input="onSearchInput"
                      @keydown.enter="searchStatueProfiles">
                    <img class="px-3 cursor-pointer" src="../assets/images/circle-x-icon.png" alt=""
                      @click="clearSearchField">
                    <div class="explore-input-label">Search</div>
                  </div>
                  <div ref="searchList" class="pt-3 search-list bg-black flex flex-col gap-2 text-gray"
                    :class="statueProfiles.length > 0 ? 'flex' : 'hidden'">
                    <router-link :to="`/productprovenance?id=${statueProfile.serialNumber}`"
                      v-for="statueProfile in statueProfiles" :key="statueProfile">
                      <div class="text-start px-14">
                        <div class="text-base">{{ statueProfile.product.productName }}</div>
                        <div class="text-xs">{{ statueProfile.serialNumber }}</div>
                      </div>
                    </router-link>
                  </div>
                </div>
              </div>
              <div class="mt-14">
                <div class="mt-1 pl-4 text-start text-xs text-red"
                  v-if="statueProfiles.length === 0 && isSearchFinished">Serial number not found.</div>
                <div class="flex justify-end" :class="statueProfiles.length === 0 && isSearchFinished ? 'pt-1' : 'pt-6'">
                  <div class="explore-button" @click="searchStatueProfiles">{{ isSearching ? `Searching...` : `Search` }}</div>
                </div>
              </div>
            </div>
          </div>
         </div>
      </div>
    </div>
    <div class="flex justify-end px-7 block md:hidden">
      <div class="signout-button cursor-pointer" @click="logout">Sign Out</div>
    </div>
    <div class="pt-10 md:pt-20 pb-40 container pl-4 pr-4 md:px-0">
      <div class="flex justify-between">
        <div class="flex gap-3 md:gap-9 font-gotham text-start justify-center md:justify-start text-base flex-wrap">
          <div class="flex items-center">Total items: {{ownedStatues.length}}</div>
          <div class="flex gap-3 items-center">
            Quota: {{ownedStatues.length}}/{{userPackage !== null ? userPackage.numOfProfiles : `0`}}
            <img class="cursor-pointer" src="@/assets/images/question-icon.png" alt=""
              @click="openPackageListingModal">
          </div>
          <div class="flex gap-3 items-center">
            Storage used:
            {{totalStorageUsed.toFixed(2)}}/{{userPackage !== null ? userPackage.numOfProfiles * userPackage.sizePerProfile : `0`}}
            <img class="cursor-pointer" src="@/assets/images/question-icon.png" alt=""
              @click="openPackageListingModal">
            <div class="">Plan ends on: {{ userPackage !== null ? moment(userPackage.subscriptionEndDate).format('L') : `` }}</div>
          </div>
        </div>
        <div class="signout-button cursor-pointer hidden md:block" @click="logout">Sign Out</div>
      </div>
      <div class="profile-list-container mt-20 mb-10">
        <div class="pt-16 text-center" v-if="!isLoading && ownedStatues.length === 0">
          You do not have any statues registered. <br>
          Contact admin@xm-studios.com for assistance.
        </div>
        <div class="profile-list grid grid-cols-2 md:grid-cols-4 gap-3 md:gap-10" v-else>
          <template v-for="statue in ownedStatues" :key="statue">
            <div class="profile-item">
              <router-link class="text-white" :to="`/productprovenance?id=${statue.serialNumber}&user=${userId}`">
                <div class="display-image bg-top bg-cover bg-no-repeat"
                  :style="{ backgroundImage: `url(${statue.product.productDisplayImage})` }"></div>
              </router-link>
              <div class="px-1 text-start">
                <router-link class="text-white" :to="`/productprovenance?id=${statue.serialNumber}&user=${userId}`">
                  <div class="text-sm md:text-base font-gotham text-ellipsis"
                    :title="statue.product.productName">
                    {{ statue.product.productName }}
                  </div>
                </router-link>
                <div class="flex justify-between text-xs md:text-sm">
                  <div class="">{{ statue.productAutograph.length }} entries</div>
                  <div class="">
                    {{ statue.totalAutographsSizeInMB.toFixed(2) }} MB used
                  </div>
                </div>
                <div class="pt-2 flex justify-between">
                  <img class="cursor-pointer" src="@/assets/images/upload-icon1.png" alt=""
                    @click="openUploadEntryModal(statue)">
                  <img class="cursor-pointer" src="@/assets/images/trash-icon-red.png" alt=""
                    @click="openDeleteEntriesModal(statue.productId)">
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
      <!-- <div class="flex justify-end">
        <div class="signout-button border-1 border-white" @click="openUploadEntryModal">Upload</div>
      </div> -->
    </div>
  </div>
  <div class="floatingSearchModal fixed top-0 min-h-screen w-full block md:hidden"
    v-if="isFloatingSearchBarMobile">
    <div class="explore-container fixed md:absolute top-1/2 md:top-12 right-0">
      <div class="explore-box-profile bg-black px-6 py-8 border-1 border-white"
        v-click-outside="closeFloatingSearchBarMobile">
        <div class="flex flex-col">
          <div class="relative">
            <div class="explore-input search">
              <div class="flex">
                <img class="px-3" src="../assets/images/search-icon.png" alt="">
                <input :value="searchKey" class="flex-auto" type="text" placeholder="Serial number or character name"
                  v-debounce:400ms="onSearchTyping"
                  @input="onSearchInput"
                  @keydown.enter="searchStatueProfiles">
                <img class="px-3 cursor-pointer" src="../assets/images/circle-x-icon.png" alt=""
                  @click="clearSearchField">
                <div class="explore-input-label">Search</div>
              </div>
              <div ref="searchList" class="pt-3 search-list bg-black flex flex-col gap-2 text-gray"
                :class="statueProfiles.length > 0 ? 'flex' : 'hidden'">
                <router-link :to="`/productprovenance?id=${statueProfile.serialNumber}`"
                  v-for="statueProfile in statueProfiles" :key="statueProfile">
                  <div class="text-start px-14">
                    <div class="text-base">{{ statueProfile.product.productName }}</div>
                    <div class="text-xs">{{ statueProfile.serialNumber }}</div>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
          <div class="mt-14">
            <div class="mt-1 pl-4 text-start text-xs text-red"
              v-if="statueProfiles.length === 0 && isSearchFinished">Serial number not found.</div>
            <div class="flex justify-end" :class="statueProfiles.length === 0 && isSearchFinished ? 'pt-1' : 'pt-6'">
              <div class="explore-button" @click="searchStatueProfiles">{{ isSearching ? `Searching...` : `Search` }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <PackageListingModal
    :modalClose="closePackageListingModal"
    :modalStatus="isPackageListingModal"
    :packages="packages"
    :userPackage="userPackage"
    :closeTransaction="closePackageListingModalTransaction"
  />
  <DeleteEntriesModal
    :modalClose="closeDeleteEntriesModal"
    :modalStatus="isDeleteEntriesModal"
    :productId="currentProductId"
  />
  <AddAutographModal
    :modalClose="closeUploadEntryModal"
    :modalStatus="isUploadEntryModal"
    :productArtists="artists"
  />
</template>

<script>
import { onMounted, ref } from 'vue';
import vClickOutside from 'click-outside-vue3';
import { vue3Debounce } from 'vue-debounce';
import moment from 'moment';
import axios from 'axios';
import { xmAxios } from '@/plugins/axios';
// import UploadEntryModal from '@/components/UploadEntryModal.vue';
import AddAutographModal from '@/components/AddAutographModal.vue';
import PackageListingModal from '@/components/PackageListingModal.vue';
import DeleteEntriesModal from '@/components/DeleteEntriesModal.vue';
import FullPageLoader from '@/components/Share/FullPageLoader.vue';

export default {
  directives: {
    clickOutside: vClickOutside.directive,
    debounce: vue3Debounce({
      lock: true,
    }),
  },
  components: {
    PackageListingModal,
    DeleteEntriesModal,
    AddAutographModal,
    FullPageLoader,
  },
  data() {
    const statues = [
      {
        name: 'Captain Marvel',
        url: '/productprovenance?id=8CVDB5G4&user=1507c817-f74d-448e-bc64-08d998f9672d',
      },
      {
        name: 'Captain Marvel',
        url: '/productprovenance?id=8CVDB5G4&user=1507c817-f74d-448e-bc64-08d998f9672d',
      },
      {
        name: 'Captain Marvel',
        url: '/productprovenance?id=8CVDB5G4&user=1507c817-f74d-448e-bc64-08d998f9672d',
      },
      {
        name: 'Captain Marvel',
        url: '/productprovenance?id=8CVDB5G4&user=1507c817-f74d-448e-bc64-08d998f9672d',
      },
      {
        name: 'Captain Marvel',
        url: '/productprovenance?id=8CVDB5G4&user=1507c817-f74d-448e-bc64-08d998f9672d',
      },
    ];
    const ownedStatues = ref([]);
    // const productProvenance = ref();
    const userId = ref(null);
    const isLoading = ref(true);
    const isTransactionLoading = ref(false);
    const artists = ref([]);
    const packages = ref([]);
    const userPackage = ref(null);
    const totalStorageUsed = ref(0);
    const isSearchActive = ref(false);
    const isSearchFinished = ref(false);
    const isSearching = ref(false);
    const searchKey = ref('');
    const statueProfiles = ref([]);
    const searchStatueProfiles = async () => {
      isSearching.value = true;
      const searchUrl = `${process.env.VUE_APP_ROUTER_DOMAIN}/api/xm/product/v1/searchproductserialnumber/${searchKey.value}`;
      await axios
        .get(searchUrl)
        .then((res) => {
          isSearchFinished.value = true;
          // console.log(res.data);
          statueProfiles.value = res.data;
          isSearchActive.value = res.data.length > 0;
          isSearching.value = false;
        })
        .catch(() => {
          isSearchFinished.value = true;
          isSearchActive.value = false;
          isSearching.value = false;
        });
    };
    const onSearchInput = async (event) => {
      searchKey.value = event.target.value;
    };
    const onSearchTyping = async () => {
      // console.log(searchKey.value);
      searchStatueProfiles();
    };
    const clearSearchField = () => {
      statueProfiles.value = [];
      searchKey.value = '';
      isSearchActive.value = false;
      isSearchFinished.value = false;
      // this.$refs.searchList.classList.add('hidden');
    };

    const logout = () => {
      localStorage.removeItem('token');
      localStorage.removeItem('user_uuid');
      localStorage.removeItem('user_name');
      this.$router.push('/');
    };

    const isPackageListingModal = ref(false);

    const openPackageListingModal = () => {
      isPackageListingModal.value = true;
      document.body.classList.add('modal-open');
    };

    const closePackageListingModal = () => {
      isPackageListingModal.value = false;
      document.body.classList.remove('modal-open');
    };

    const closePackageListingModalTransaction = () => {
      isPackageListingModal.value = false;
      isTransactionLoading.value = true;
      document.body.classList.remove('modal-open');
    };

    const isDeleteEntriesModal = ref(false);
    const currentProductId = ref(null);

    const openDeleteEntriesModal = (productId) => {
      isDeleteEntriesModal.value = true;
      currentProductId.value = productId;
      document.body.classList.add('modal-open');
    };

    const closeDeleteEntriesModal = () => {
      isDeleteEntriesModal.value = false;
      document.body.classList.remove('modal-open');
    };

    const isFloatingSearchBar = ref(false);
    const isFloatingSearchBarMobile = ref(false);

    const openFloatingSearchBar = () => {
      isFloatingSearchBar.value = true;
      // document.body.classList.add('modal-open');
    };

    const closeFloatingSearchBar = () => {
      isFloatingSearchBar.value = false;
      // clearSearchField();
      // document.body.classList.remove('modal-open');
    };
    const openFloatingSearchBarMobile = () => {
      isFloatingSearchBarMobile.value = true;
      document.body.classList.add('modal-open');
    };

    const closeFloatingSearchBarMobile = () => {
      isFloatingSearchBarMobile.value = false;
      // clearSearchField();
      document.body.classList.remove('modal-open');
    };

    const isQuotaReached = (statue) => {
      // check if quota max up and check item with 0 entries 0 MB
      if (userPackage.value !== null &&
          userPackage.value.numOfProfiles === ownedStatues.value.length &&
          statue.productAutograph.length === 0 &&
          statue.totalAutographsSizeInMB === 0) {
        return true;
      }
      return false;
    };

    const isUploadEntryModal = ref(false);

    const openUploadEntryModal = (statue) => {
      if (isQuotaReached(statue)) {
        openPackageListingModal();
      } else {
        // console.log(statue.artists);
        artists.value = statue.artists;
        isUploadEntryModal.value = true;
      }
    };

    const closeUploadEntryModal = () => {
      isUploadEntryModal.value = false;
    };
    onMounted(async () => {
      // console.log('onMounted');
      userId.value = localStorage.getItem('user_uuid');

      if (localStorage.getItem('token') === null) {
        this.$router.push('/');
      }
      // get list of packages
      const getPackagesUrl = `${process.env.VUE_APP_ROUTER_DOMAIN}/api/xm/product/v1/getpackages`;
      xmAxios.get(getPackagesUrl).then((response) => {
        packages.value = response.data;
      });
      // get user package
      const getSubscriptionStatusUrl = `${process.env.VUE_APP_ROUTER_DOMAIN}/api/xm/product/v1/getSubscriptionStatus`;
      await xmAxios.get(getSubscriptionStatusUrl).then(async (response) => {
        // userPackage.value = packages.value.filter((e) => e.collectibleProfilePackageId === response.data.collectibleProfilePackageId);
        userPackage.value = response.data;
      });
      // console.log(userPackage);
      // get list of user owned statues
      const getProductSerialNumberWithAutographsUrl = `
        ${process.env.VUE_APP_ROUTER_DOMAIN}/api/xm/product/v1/productserialnumberwithautographs/${userId.value}
      `;
      await xmAxios.get(getProductSerialNumberWithAutographsUrl).then(async (response) => {
        // console.log(response.data);
        let totalAutographsSize = 0;
        const promises = response.data.map(async (value) => {
          totalAutographsSize += value.totalAutographsSizeInMB;
          let productSerialNumber = null;
          const productArtistIds = [];
          // console.log(value.serialNumber);

          // get artists from artist ids
          if (value.serialNumber !== null) {
            const productProvenanceUrl = `${process.env.VUE_APP_ROUTER_DOMAIN}/api/xm/product/v1/productprovenance/${value.serialNumber}`;
            await xmAxios.get(productProvenanceUrl).then((provenanceResponse) => {
              const artistUrl = `${process.env.VUE_APP_ROUTER_DOMAIN}/api/xm/artist/v1/artists`;
              xmAxios.get(artistUrl).then((artistRes) => {
                provenanceResponse.data.product.artistIds.forEach((id) => {
                  const artist = artistRes.data.find((x) => x.artistId === id);
                  productArtistIds.push(artist);
                });
              });
              // console.log(productArtistIds);
            });
          }
          // call api to get product serial number
          const getProductSerialNumber = `
            ${process.env.VUE_APP_ROUTER_DOMAIN}/api/xm/product/v1/${value.serialNumber}/productserialnumber
          `;
          await xmAxios.get(getProductSerialNumber).then((response2) => {
            // console.log(response2.data);
            productSerialNumber = response2.data;
          });
          return Object.assign(value, {
            product: productSerialNumber.product,
            artists: productArtistIds,
          });
        });
        totalStorageUsed.value = totalAutographsSize;
        ownedStatues.value = await Promise.all(promises);
        isLoading.value = false;
      }).catch(() => {
        isLoading.value = false;
      });
      // console.log(ownedStatues.value);
    });

    return {
      moment,
      statues,
      ownedStatues,
      isUploadEntryModal,
      isPackageListingModal,
      isDeleteEntriesModal,
      userId,
      isLoading,
      artists,
      packages,
      userPackage,
      isTransactionLoading,
      totalStorageUsed,
      searchKey,
      isSearchActive,
      isSearchFinished,
      isSearching,
      statueProfiles,
      isFloatingSearchBar,
      currentProductId,
      openUploadEntryModal,
      closeUploadEntryModal,
      openPackageListingModal,
      closePackageListingModal,
      closePackageListingModalTransaction,
      openDeleteEntriesModal,
      closeDeleteEntriesModal,
      logout,
      clearSearchField,
      searchStatueProfiles,
      onSearchInput,
      onSearchTyping,
      openFloatingSearchBar,
      closeFloatingSearchBar,
      isFloatingSearchBarMobile,
      openFloatingSearchBarMobile,
      closeFloatingSearchBarMobile,
      isQuotaReached,
    };
  },
};
</script>

<style lang="scss">
.font-gotham {
  font-family: 'gotham';
}
.profile-page {
  background: black;
  color: white;
  .container {
    max-width: 868px;
  }
  a[class^=router-link] {
    color: white;
  }
}
.signout-button {
  font-family: 'gotham';
  width: 114px;
  // border: 1px solid #FFFFFF;
  padding: 8px 0;
  cursor: pointer;
  font-size: 16px;
  color: white !important;
}
.profile-list-container {
  min-height: 488px;
}
.profile-item {
  width: 178px;
  background: black;
  .display-image {
    width: 178px;
    height: 224px;
  }
}
@media (max-width: 768px) {
  .profile-item {
    width: auto;
    .display-image {
      width: auto;
      height: 192px;
    }
  }
}
.floatingSearchModal {
  background: #000000e6;
  z-index: 999;
}
.explore-container {
  padding: 0px 0px;
}
.explore-box-profile {
  width: 500px;
  max-height: 376px;
}
@media (max-width: 768px) {
  .explore-container {
    width: 100%;
    padding: 0px 12px;
    transform: translateY(-50%);
  }
  .explore-box-profile {
    width: 100%;
  }
  .explore-input.search {
    .search-list {
      height: 232px;
      overflow: auto;
    }
  }
}
.explore-input {
  position: relative;
  border: 1px solid #FFFFFF;
  border-radius: 4px;
  padding: 16px 0;
  background: black;
  &.search {
    position: absolute !important;
    // width: -webkit-fill-available;
    width: 100%;
    z-index: 0;
  }
  input {
    background: none !important;
    border: none !important;
    outline: none !important;
  }
  .explore-input-label {
    position: absolute;
    top: 0;
    left: 16px;
    transform: translateY(-50%);
    background: black;
    padding: 0 4px;
    font-size: 12px;
    color: white;
  }
  .search-list {
    a:hover {
      color: white;
    }
  }
}
.explore-box-profile {
  .search-list {
    height: 500px;
    overflow: auto;
  }
}
.explore-button {
  font-family: 'gotham';
  width: 114px;
  border: 1px solid #FFFFFF;
  padding: 8px 0;
  cursor: pointer;
  font-size: 12px;
  color: white !important;
}
</style>
