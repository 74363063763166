import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import Profile from '../views/Profile.vue';
import ProductProvenance from '../views/ProductProvenance.vue';
import productLibrary from '../views/ProductLibrary.vue';
import provenanceEvent from '../views/ProvenanceEvent.vue';
import PaymentSuccess from '../views/PaymentSuccess.vue';
import PaymentFailed from '../views/PaymentFailed.vue';

const routes = [
  {
    path: '',
    component: Home,
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
  },
  {
    path: '/productprovenance',
    name: 'Product Provenance',
    component: ProductProvenance,
  },
  {
    path: '/productlibrary',
    name: 'Product Library',
    component: productLibrary,
  },
  {
    path: '/provenanceevent',
    name: 'Provenance Event',
    component: provenanceEvent,
  },
  {
    path: '/payment/success',
    name: 'Payment Success',
    component: PaymentSuccess,
  },
  {
    path: '/payment/failed',
    name: 'Payment Failed',
    component: PaymentFailed,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
